import React, { Component } from "react";
import Props from "../../libs/Props";
import GameLib from "../../libs/GameLib";
import "./MyBag.css";
import Gold from "../../assets/ui/Gold.png";

import { Popover } from "antd";
import { ToastContainer, toast } from "react-toastify";
class MyBag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myGameProps: [],
      crafts: [],
      GoldBalance: 0,
      tabHeaderList: ["Props", "Crafts"],
      currentTabIndex: 0,
      isEquipment:false,
      equipmentCraftId:0
    };
  }

  async componentDidMount() {
    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));

    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    var gameLib = new GameLib(chainId);

    this.setState({
      gameLib: gameLib,
      myBurnWallet: myBurnWallet,
    });

   await this.getProps(gameLib, myBurnWallet);

  
  }

  async useProp(tokenId) {
    const response = await toast.promise(
      async () => {

        await this.state.gameLib.useProp(tokenId, this.state.myBurnWallet.address);
        await this.componentDidMount();
        this.props.handleUseProps();
    
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
);
 
  }

  changeTab = async (index) => {
    this.setState({
      currentTabIndex: index,
    });

    if (index == 0) {
      await this.getProps(this.state.gameLib, this.state.myBurnWallet);
    } else {
      await this.getCrafts(this.state.gameLib, this.state.myBurnWallet);
      await this.getEuipment(this.state.gameLib, this.state.myBurnWallet);
    }
  };

  getProps = async (gameLib, myBurnWallet) => {
    var batchIds = Array.from(Props.keys());
    // batchIds.shift();
    var batchAddres = new Array(batchIds.length).fill(myBurnWallet.address);
    var balanceBatch = await gameLib.getGamePropsBatch(batchAddres, batchIds);

    gameLib.addBurnWallet(myBurnWallet);

    var tokenIdsAndAmount = [];
    for (var i = 0; i < balanceBatch.length; i++) {
      if (balanceBatch[i] != 0) {
        tokenIdsAndAmount.push({
          tokenId: batchIds[i],
          amount: balanceBatch[i],
        });
      }
    }

    this.setState({
      myGameProps: tokenIdsAndAmount,
    });
  };

  getCrafts = async (gameLib, myBurnWallet) => {
    var crafts = await gameLib.getCrafts(myBurnWallet.address);
    this.setState({
      crafts: crafts,
    });
    console.log("craft", crafts);
  };

  getEuipment=async(gameLib, myBurnWallet)=>{
    var equipment=await gameLib.getEquipment(myBurnWallet.address);
    console.log("equipment",equipment)
    this.setState({
      isEquipment:equipment[0],
      equipmentCraftId:equipment[1]
    })
  }

  setEquipment=async(craftId)=>{
    const response = await toast.promise(
      async () => {

        await this.state.gameLib.setEquipment(craftId,this.state.myBurnWallet.address)
        await this.getEuipment(this.state.gameLib, this.state.myBurnWallet)
        await this.props.handleUseProps();
    
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
);

  }

  render() {
    return (
      <div>
        <ToastContainer></ToastContainer>
        {/* <div className="MyBagGold">
          <div className="MyBagGoldTitle">
            <img src={Gold} width={24}/>
            &nbsp;
            <p>Gold Coin</p>
          </div>
          <div>{this.state.GoldBalance}</div>
        </div> */}

        <div className="tabs">
          {this.state.tabHeaderList.map((item, index) => (
            <label key={index}>
              <input
                type="radio"
                name="radio"
                checked={this.state.currentTabIndex == index ? true : false}
                value={index}
                onChange={this.changeTab.bind(this, index)}
              />
              <span>{item}</span>
            </label>
          ))}
        </div>

        {this.state.currentTabIndex == 0 ? (
          this.state.myGameProps.length != 0 ? (
            <ul className="MyBagProps">
              {this.state.myGameProps.map((item, index) => (
                <li key={index}>
                  <Popover
                    trigger="hover"
                    content={
                      <div>
                        {Props.get(item.tokenId).metadata.description}
                        {Props.get(item.tokenId).metadata.category == 2 ? (
                          <div
                            className="MyBagUseProp"
                            onClick={this.useProp.bind(this, item.tokenId)}
                          >
                            Use
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    }
                    title={Props.get(item.tokenId).metadata.name}
                  >
                    <div className="MyBagItemAmount">{item.amount}</div>
                    <img src={Props.get(item.tokenId).image} width="100%" />
                  </Popover>
                </li>
              ))}
            </ul>
          ) : (
            <div></div>
          )
        ) : (
          <div>
            {this.state.crafts.length != 0 ? (
              <ul className="MyCrafts">
                {this.state.crafts.map((item, index) => (
                  <li key={index}>
                    <Popover
                      trigger="hover"
                      content={
                        <div className="MyCraftsPopover">

                          <div className="MyCraftsPopoverItem">
                            <p>{item.name}</p>
                          </div>
                          <div className="MyCraftsPopoverItem">
                            <p>Attack</p>
                            <p>{item.attributes[0].value}</p>
                          </div>
                          <div className="MyCraftsPopoverItem">
                            <p>Defense&nbsp;</p>
                            <p>{item.attributes[1].value}</p>
                          </div>
                          <div
                            className="MyBagUseProp"
                            onClick={this.setEquipment.bind(this, item.tokenId)}
                          >
                            Equip
                          </div>
                        </div>
                      }
                    >
                      {
                        this.state.isEquipment&&this.state.equipmentCraftId==item.tokenId?(<div className="MyCraftEquipment">Equipped</div>):(
                          <div></div>
                        )
                      }
                        
                      <img src={item.image} width="100%" />
                    </Popover>
                  </li>
                ))}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default MyBag;
