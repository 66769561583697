import { useEffect } from "react";
import "./SnowLand.css";
import snow from "../../../assets/lands/snow.png";
import Princess from "../../../assets/gif/snow/Princess.gif";
import ChatDialog from "../../../assets/ui/ChatDialog.svg";
import ChatBattle from "../../../assets/ui/ChatBattle.svg";
import Ghost from "../../../assets/gif/snow/Ghost.gif"
import Skeleton from "../../../assets/gif/snow/Skeleton.gif"
import Fire from "../../../assets/gif/snow/Fire.gif"
function SnowLand(props) {
  useEffect(() => {});

  const showMarkertDrawer = () => {
    props.handleShowDrawer("Market");
  };
  return (
    <div className="SnowLand">
      <div
        className="SnowLandPrincess"
        onClick={() => {
          props.handleModalOperate({ type: "dialog", value: "princess" });
        }}
      >
        <div className="LandChat">
          <img src={ChatDialog} width={8} />
          &nbsp;
          <p>Princess</p>
        </div>
        <img src={Princess} width={16} />
      </div>


      <div className="SnowLandGhost" onClick={()=>{
        props.handleModalOperate({type:"battle",value:"3"})
      }}>
        <div className="LandChat">
          <img src={ChatBattle} width={8}/>
          <p>Ghost</p>
          </div>
        <img src={Ghost} width={18} />
      </div>

      <div className="SnowLandSkeleton" onClick={()=>{
        props.handleModalOperate({type:"battle",value:"4"})
      }}>
        <div className="LandChat">
          <img src={ChatBattle} width={8}/>
          <p>Skeleton</p>
          </div>
        <img src={Skeleton} width={18} />
      </div>
      <img src={Fire} width={18} className="SnowLandFire"/>
      <img src={snow} width={460} />
  
    </div>
  );
}

export default SnowLand;
