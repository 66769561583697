import { useEffect } from "react";
import "./MainTown.css";
import MainTownLand from "../../../assets/lands/mainland.png";
import AnimalWolf from "../../../assets/gif/mainland/Wolf.gif";
import AnimalBear from "../../../assets/gif/mainland/Bear.gif";
import AnimalBoar from "../../../assets/gif/mainland/Boar.gif";
import AnimalBunny from "../../../assets/gif/mainland/Bunny.gif";
import AnimalDeer1 from "../../../assets/gif/mainland/Deer1.gif";
import AnimalDeer2 from "../../../assets/gif/mainland/Deer2.gif";
import AnimalFox from "../../../assets/gif/mainland/Fox.gif";
import King from "../../../assets/gif/mainland/King.gif";

import Cloud1 from "../../../assets/gif/mainland/Cloud1.png"
import Cloud2 from "../../../assets/gif/mainland/Cloud2.png"
import Cloud3 from "../../../assets/gif/mainland/Cloud3.png"
import Cloud4 from "../../../assets/gif/mainland/Cloud4.png"

import Goblin1 from "../../../assets/gif/mainland/Goblin1.gif";
import Goblin2 from "../../../assets/gif/mainland/Goblin2.gif";
import Goblin3 from "../../../assets/gif/mainland/Goblin3.gif";

import Fountain from "../../../assets/gif/mainland/Fountain.gif"
import ChatDialog from "../../../assets/ui/ChatDialog.svg"
import ChatBattle from "../../../assets/ui/ChatBattle.svg"
function MainTown(props) {
  useEffect(() => {});



  return (
    <div className="MainTown">
      <img src={Cloud1} className="Cloud1"/>
      <img src={Cloud2} className="Cloud2"/>
      <img src={Cloud3} className="Cloud3"/>
      <img src={Cloud4} className="Cloud4"/>
      <div className="MainTownKing" onClick={()=>{
        props.handleModalOperate({type:"dialog",value:"king"})
      }}>
        <div className="LandChat">
        <img src={ChatDialog} width={8}/>&nbsp;
          <p>King</p>
          </div>
        <img src={King} width={24} />
      </div>


      <div className="MainTownGoblin1" onClick={()=>{
        props.handleModalOperate({type:"battle",value:"0"})
      }}>
        <div className="LandChat">
          <img src={ChatBattle} width={8}/>
          <p>Goblin</p>
          </div>
        <img src={Goblin1} width={24} />
      </div>

      <div className="MainTownGoblin2" onClick={()=>{
        props.handleModalOperate({type:"battle",value:"1"})
      }}>
        <div className="LandChat">
          <img src={ChatBattle} width={8}/>
          <p>Goblin</p>
          </div>
        <img src={Goblin2} width={24} />
      </div>

      <div className="MainTownGoblin3" onClick={()=>{
        props.handleModalOperate({type:"battle",value:"2"})
      }}>
        <div className="LandChat">
          <img src={ChatBattle} width={8}/>
          <p>Goblin</p>
          </div>
        <img src={Goblin3} width={14} />
      </div>


      <img src={Fountain} className="MainTownFountain"/>
      <img src={AnimalWolf} className="MainTown_AnimalWolf" />
      <img src={AnimalBear} className="MainTown_AnimalBear" />
      <img src={AnimalBoar} className="MainTown_AnimalBoar" />
      <img src={AnimalBunny} className="MainTown_AnimalBunny" />
      <img src={AnimalDeer1} className="MainTown_AnimalDeer1" />
      <img src={AnimalDeer2} className="MainTown_AnimalDeer2" />
      <img src={AnimalFox} className="MainTown_AnimalFox" />
      <img src={MainTownLand} />
    </div>
  );
}

export default MainTown;
