import { useEffect } from "react";
import "./SpellLand.css";
import MagicSchool from "../../../assets/lands/magicScool.png";

import Animate1 from "../../../assets/gif/school/animate1.gif";
import Animate2 from "../../../assets/gif/school/animate2.gif";
import Animate3 from "../../../assets/gif/school/animate3.gif";
import Wizard from "../../../assets/gif/school/wizard.gif"
import ChatSpell from "../../../assets/ui/ChatSpell.svg"
function SpellLand(props) {
  useEffect(() => {});

  const showDrawer = () => {
    props.handleShowDrawer("Spells");
  };
  return (
    <div className="SpellLand" >
      <img src={Animate1} className="SpellLand_Animate1" />
      <img src={Animate1} className="SpellLand_Animate2" />
      <img src={Animate2} className="SpellLand_Animate3" />
      <img src={Animate3} className="SpellLand_Animate4" />

      <div  className="SchoolLandWitch"  onClick={showDrawer}>
        <div className="LandChat">
           
           <img src={ChatSpell} width={12}/>
           <p>Spells</p>
         </div>
         <img src={Wizard} width={20}/>
        </div>

      <img src={MagicSchool}  />
    </div>
  );
}

export default SpellLand;
