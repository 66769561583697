import Gold from "../assets/props/Gold.png"
import MonsterBone from "../assets/props/MonsterBone.png"
import FireMagicBook from "../assets/props/FireMagicBook.png"
import ForestMagicBook from "../assets/props/ForestMagicBook.png"
import MagicCrystal from "../assets/props/MagicCrystal.png"
import SoulScroll from "../assets/props/SoulScroll.png"
import HealthPotion from "../assets/props/HealthPotion.png"
import ManaPotion from "../assets/props/ManaPotion.png"


import GreenGem from "../assets/props/GreenGem.png"
import YellowGem from "../assets/props/YellowGem.png"

const Props = new Map([
  [
    0,
    {
      tokenId: 0,
      image: Gold,
      price: 0,
      metadata: {
        name: "God",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/Gold.png",
        description: "The currency of the wizarding world",
        category: 0,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],
  [
    1,
    {
      tokenId: 1,
      image: MonsterBone,
      price: 5,
      metadata: {
        name: "Monster Bone",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/MonsterBone.png",
        description: "Bones dropped from monsters",
        category: 1,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],
  [
    2,
    {
      tokenId: 2,
      image: FireMagicBook,
      price: 10,
      metadata: {
        name: "Fire Magic Book",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/FireMagicBook.png",
        description: "Magic book written by Vulcan",
        category: 1,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],[
    3,
    {
      tokenId: 3,
      image: ForestMagicBook,
      price: 10,
      metadata: {
        name: "Forest Magic Book",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/ForestMagicBook.png",
        description: "A masterpiece from the forest spirit",
        category: 1,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],[
    4,
    {
      tokenId: 4,
      image: MagicCrystal,
      price: 5,
      metadata: {
        name: "Magic Crystal",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/MagicCrystal.png",
        description: "A universal material",
        category: 1,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],[
    5,
    {
      tokenId: 5,
      image: SoulScroll,
      price: 5,
      metadata: {
        name: "Soul Scroll",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/SoulScroll.png",
        description: "The soul is sealed in the scroll",
        category: 1,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],[
    6,
    {
      tokenId: 6,
      image: HealthPotion,
      price: 5,
      metadata: {
        name: "Health Potion",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/HealthPotion.png",
        description: "Potion to restore health",
        category: 2,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 10,
        restoreMP: 0,
      },
    },
  ],[
    7,
    {
      tokenId: 7,
      image: ManaPotion,
      price: 5,
      metadata: {
        name: "Mana Potion",
        image: "https://bafybeibl52pxy22lzltrprg5uwvmb374oxpvas46d46zlwmiax72kjryzm.ipfs.nftstorage.link/ManaPotion.png",
        description: "Potion to restore mana",
        category: 2,
        boostMATK: 0,
        boostDEF: 0,
        boostHP: 0,
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 10,
      },
    },
  ],[
    8,
    {
      tokenId: 8,
      image: YellowGem,
      price: 5,
      metadata: {
        name: "Yellow Gem",
        image: "https://bafkreiaq4kmvenkhjl55rjmsii7ynm4nphj6ozmctfw7ek2gbf3npjltcy.ipfs.nftstorage.link",
        description: "Material for strengthening props",
        category: 3,
        boostMATK: 1, 
        boostDEF: 0,  
        boostHP: 0, 
        boostMP: 0, 
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ],[
    9,
    {
      tokenId: 9,
      image: GreenGem,
      price: 5,
      metadata: {
        name: "Green Gem",
        image: "https://bafybeieuvhuipmkm26z2zsstje6uaxm4gexmi64gyx7oq3pzd67yuwm5ha.ipfs.nftstorage.link/GreenGem.png",
        description: "Material for strengthening props",
        category: 3,
        boostMATK: 0, 
        boostDEF: 1,  
        boostHP: 0, 
        boostMP: 0,
        restoreHP: 0,
        restoreMP: 0,
      },
    },
  ]
]);

export default Props;
