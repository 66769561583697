import React, { Component } from "react";
import html2canvas from "html2canvas";
import "./Craft.css";
import { SketchPicker } from "react-color";
import broom from "../../assets/ui/broom.svg";
import eraser from "../../assets/ui/eraser.svg";
import pencil from "../../assets/ui/pencil.svg";
import Gold from "../../assets/ui/GoldAnimate.gif";
import GameLib from "../../libs/GameLib";
import AddIcon from "../../assets/ui/AddIcon.svg";
import { message, Popover } from "antd";
import Props from "../../libs/Props";
import { ToastContainer, toast } from "react-toastify";
class Craft extends Component {
  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    this.state = {
      pixelData: new Array(256).fill("rgba(100,100,100,0)"),
      imgBase64: "",
      isEraser: false,
      currentColor: "#000",
      mouseDown: false,
      displayColorPicker: false,
      craftName: "",
      currentChainId: "",
      tabHeaderList: ["New", "Enchant"],
      currentTabIndex: 0,
      craftProps: [],
      craftMaterials: [],
      gameLib: "",
      myBurnWallet: "",
      selectCraftProp: "no",
      selectCraftMaterial: "no",
    };
  }

  async componentDidMount() {
    // document.addEventListener("keyup", this.handleKey, false);
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    this.setState({
      currentChainId: chainId,
    });

    var gameLib = new GameLib(chainId);
    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    gameLib.addBurnWallet(myBurnWallet);

    this.setState({
      gameLib: gameLib,
      myBurnWallet: myBurnWallet,
    });
  }

  handleKey = (event) => {
    // if (event.key == "e" || event.key == "E") {
    //   this.setState({
    //     currentColor: "rgba(100,100,100,0)",
    //     isEraser: true,
    //   });
    // } else if (event.key == "p" || event.key == "P") {
    //   this.setState({
    //     currentColor: "#000000",
    //     isEraser: false,
    //   });
    // }
  };
  handleDownloadImage = async () => {
    const element = this.printRef.current;
    const canvas = await html2canvas(element, {
      backgroundColor: null,
      scale: 0.5,

      //   height:156,
    });

    const data = canvas.toDataURL("image/png");
    console.log("data", data);
    this.setState({
      imgBase64: data,
    });
  };

  fillColor = async (index) => {
    console.log(index);
    var pixelData = this.state.pixelData;
    pixelData[index] = this.state.currentColor;
    this.setState({
      pixelData: pixelData,
    });
  };

  selectEraser = (isSelect) => {
    if (isSelect) {
      this.setState({
        currentColor: "rgba(100,100,100,0)",
        isEraser: isSelect,
      });
    } else {
      this.setState({
        currentColor: "#000000",
        isEraser: isSelect,
      });
    }
  };

  cleanDraw = () => {
    this.setState({
      pixelData: new Array(256).fill("rgba(100,100,100,0)"),
      imgBase64: "",
    });
  };

  changeColor = (color) => {
    this.setState({
      currentColor: color.hex,
    });
  };

  handleMove = (index, event) => {
    if (this.state.mouseDown) {
      var pixelData = this.state.pixelData;
      pixelData[index] = this.state.currentColor;
      this.setState({
        pixelData: pixelData,
      });
    }
  };

  handleMouseDown = (index) => {
    var pixelData = this.state.pixelData;
    pixelData[index] = this.state.currentColor;
    this.setState({
      pixelData: pixelData,
      mouseDown: true,
    });
  };

  handleMouseUp = async (index, event) => {
    if (this.state.mouseDown) {
      var pixelData = this.state.pixelData;
      pixelData[index] = this.state.currentColor;
      this.setState({
        pixelData: pixelData,
        mouseDown: false,
      });
    }

    this.handleDownloadImage();
  };

  handleMouseLeave = () => {
    if (this.state.mouseDown == true) {
      this.setState({
        mouseDown: false,
      });
    }
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ currentColor: color.hex });
  };

  generateCraft = async () => {
    if (this.state.craftName != "") {
      const response = await toast.promise(
        async () => {
  
          await this.state.gameLib.createCraft(
            this.state.craftName,
            this.state.imgBase64,
            this.state.myBurnWallet.address
          );
      
        },
        {
          pending: { render: "pending", position: "top-center" },
          success: "Success",
          error: "Fail",
        }
  );


    } else {
      message.error("Please give the craft a name", 3);
    }
  };

  changeTab = async (index) => {
    this.setState({
      currentTabIndex: index,
    });

    if (index == 0) {
      this.setState({
        selectCraftProp: "no",
        selectCraftMaterial: "no",
      });
    } else {
      await this.getCrafts(this.state.gameLib, this.state.myBurnWallet);
      await this.getMaterial(this.state.gameLib, this.state.myBurnWallet);
    }
  };

  getCrafts = async (gameLib, myBurnWallet) => {
    var crafts = await gameLib.getCrafts(myBurnWallet.address);
    this.setState({
      craftProps: crafts,
    });
  };

  getMaterial = async (gameLib, myBurnWallet) => {
    var batchIds = Array.from(Props.keys());
    // batchIds.shift();
    var batchAddres = new Array(batchIds.length).fill(myBurnWallet.address);
    var balanceBatch = await gameLib.getGamePropsBatch(batchAddres, batchIds);

    var tokenIdsAndAmount = [];
    for (var i = 0; i < balanceBatch.length; i++) {
      if (
        balanceBatch[i] != 0 &&
        Props.get(batchIds[i]).metadata.category == 3
      ) {
        tokenIdsAndAmount.push({
          tokenId: batchIds[i],
          amount: balanceBatch[i],
        });
      }
    }

    this.setState({
      craftMaterials: tokenIdsAndAmount,
    });
  };

  handleSelectProp = (index) => {
    this.setState({
      selectCraftProp: index,
    });
  };

  handleSelectMaterial = (index) => {
    this.setState({
      selectCraftMaterial: index,
    });
  };

  updateCraft = async () => {
    // console.log(this.state.craftProps[this.state.selectCraftProp].tokenId)
    // console.log(this.state.craftMaterials[this.state.selectCraftMaterial].tokenId)
    if (
      this.state.selectCraftProp != "no" &&
      this.state.selectCraftMaterial != "no"
    ) {
      const response = await toast.promise(
        async () => {
  
          await this.state.gameLib.updateCraft(
            this.state.craftProps[this.state.selectCraftProp].tokenId,
            this.state.craftMaterials[this.state.selectCraftMaterial].tokenId,
            this.state.myBurnWallet.address
          );
    
          this.setState({
            selectCraftProp: "no",
            selectCraftMaterial: "no",
          });
          await this.getCrafts(this.state.gameLib, this.state.myBurnWallet);
          await this.getMaterial(this.state.gameLib, this.state.myBurnWallet);
      
        },
        {
          pending: { render: "pending", position: "top-center" },
          success: "Success",
          error: "Fail",
        }
  );


    } else {
      message.error("Please select a prop and material", 2);
    }
  };

  render() {
    return (
      <div className="Craft">
        <ToastContainer></ToastContainer>
        <div className="tabs">
          {this.state.tabHeaderList.map((item, index) => (
            <label key={index}>
              <input
                type="radio"
                name="radio"
                checked={this.state.currentTabIndex == index ? true : false}
                value={index}
                onChange={this.changeTab.bind(this, index)}
              />
              <span>{item}</span>
            </label>
          ))}
        </div>

        {this.state.currentTabIndex == 0 ? (
          <div className="DrawContainer">
            <div className="DrawArea">
              <ul
                className="Draw"
                ref={this.printRef}
                onMouseLeave={this.handleMouseLeave.bind(this)}
              >
                {this.state.pixelData.map((item, index) => (
                  <li
                    key={index}
                    style={{ backgroundColor: item }}
                    onClick={this.fillColor.bind(this, index)}
                    onMouseDown={this.handleMouseDown.bind(this, index)}
                    onMouseUp={this.handleMouseUp.bind(this, index)}
                    onMouseMove={this.handleMove.bind(this, index)}
                  ></li>
                ))}
              </ul>
              <ul className="DrawGrid">
                {this.state.pixelData.map((item, index) => (
                  <li key={index}></li>
                ))}
              </ul>
            </div>
            <div className="DrawFunctions">
              <p className="DrawFunctionsTitle"> Tools</p>
              <div className="DrawTools">
                <div
                  className={
                    this.state.isEraser ? "ToolItemNormal" : "ToolItemSelect"
                  }
                  onClick={this.selectEraser.bind(this, false)}
                >
                  <img src={pencil} width={24} />
                  <p>Pencil</p>
                </div>

                <div
                  className={
                    this.state.isEraser ? "ToolItemSelect" : "ToolItemNormal"
                  }
                  onClick={this.selectEraser.bind(this, true)}
                >
                  <img src={eraser} width={24} />
                  <p>Eraser</p>
                </div>

                <div
                  onClick={this.cleanDraw.bind(this)}
                  className="ToolItemNormal"
                >
                  <img src={broom} width={24} />
                  <p>Clean</p>
                </div>
              </div>
              <p className="DrawFunctionsTitle"> Color</p>

              <div
                style={{
                  padding: "5px",
                  background: "#fff",
                  borderRadius: "0px",
                  boxShadow: "0 0 0 0px rgba(0,0,0,.1)",
                  display: "inline-block",
                  cursor: "pointer",
                }}
                onClick={this.handleClick.bind(this)}
              >
                <div
                  style={{
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    background: `${this.state.currentColor}`,
                  }}
                />
              </div>
              {this.state.displayColorPicker ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: "2",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={this.handleClose.bind(this)}
                  />
                  <SketchPicker
                    color={this.state.currentColor}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
              ) : null}

              <p className="DrawFunctionsTitle"> Preview</p>
              <div className="DrawPreview">
                <img src={this.state.imgBase64} width={128} />
              </div>
              <p className="DrawFunctionsTitle"> Name</p>

              <input
                className="CraftInput"
                placeholder="Please input name"
                onChange={(e) => {
                  this.setState({
                    craftName: e.target.value,
                  });
                }}
              />
              <div
                className="GenerateButton"
                onClick={this.generateCraft.bind(this)}
              >
                <img src={Gold} />

                <p>100 Generate</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="Strengthen">
            <p className="StrengthenTitle">Select Craft and Material</p>
            <div className="StrengthenSelect">
              <div className="StrengthenSelectProp">
                <p>Craft</p>
                <div className="StrengthenSelectPropItem">
                  {this.state.selectCraftProp != "no" ? (
                    <img
                      src={
                        this.state.craftProps[this.state.selectCraftProp].image
                      }
                      width={48}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div className="StrengthenSelectAdd">
                <img src={AddIcon} width={24} />
              </div>
              <div className="StrengthenSelectMaterial">
                <p>Material</p>
                <div className="StrengthenSelectMaterialItem">
                  {this.state.selectCraftMaterial != "no" ? (
                    <img
                      src={
                        Props.get(
                          this.state.craftMaterials[
                            this.state.selectCraftMaterial
                          ].tokenId
                        ).image
                      }
                      width={48}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="StrengthenButton"
              onClick={this.updateCraft.bind(this)}
            >
              Strengthen
            </div>
            <p className="StrengthenTitle">My Crafts</p>

            {this.state.craftProps.length != 0 ? (
              <ul className="CraftProps">
                {this.state.craftProps.map((item, index) => (
                  <li
                    key={index}
                    onClick={this.handleSelectProp.bind(this, index)}
                    id={this.state.selectCraftProp == index ? "SelectItem" : ""}
                  >
                    <Popover
                      trigger="hover"
                      content={
                        <div className="CraftPropsPopover">
                          <div className="CraftPropsPopoverItem">
                            <p>{item.name}</p>
                          </div>
                          <div className="CraftPropsPopoverItem">
                            <p>Attack </p>
                            <p>{item.attributes[0].value}</p>
                          </div>
                          <div className="CraftPropsPopoverItem">
                            <p>Defense&nbsp;</p>
                            <p>{item.attributes[1].value}</p>
                          </div>
                        </div>
                      }
                    >
                      <img src={item.image} width="100%" />
                    </Popover>
                  </li>
                ))}
              </ul>
            ) : (
              <div></div>
            )}

            <p className="StrengthenTitle">My Materials</p>

            {this.state.craftMaterials.length != 0 ? (
              <ul className="craftMaterials">
                {this.state.craftMaterials.map((item, index) => (
                  <li
                    key={index}
                    onClick={this.handleSelectMaterial.bind(this, index)}
                    id={
                      this.state.selectCraftMaterial == index
                        ? "SelectItem"
                        : ""
                    }
                  >
                    <Popover
                      trigger="hover"
                      content={
                        <div>
                          <p>{Props.get(item.tokenId).metadata.description}</p>
                          <p>
                            Attack:{Props.get(item.tokenId).metadata.boostMATK}
                          </p>
                          <p>
                            Defense:{Props.get(item.tokenId).metadata.boostDEF}
                          </p>
                        </div>
                      }
                      title={Props.get(item.tokenId).metadata.name}
                    >
                      <div className="MaterialItemAmount">{item.amount}</div>
                      <img src={Props.get(item.tokenId).image} width="100%" />
                    </Popover>
                  </li>
                ))}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Craft;
