import React, { Component } from "react";
import music from "../../assets/music.mp3";
import MusicPlayIcon from "../../assets/ui/MusicPlayIcon.png"
import MusicStopIcon from "../../assets/ui/MusicStopIcon.png"

class MusicPlayer extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            playing: false
        };
    
  
    }
 
  
    audioRef = React.createRef();
  
    handlePlay = () => {
      this.setState({ playing: true });
    };
  
    handlePause = () => {
      this.setState({ playing: false });
    };
  
    togglePlay = () => {
      if (this.state.playing) {
        this.audioRef.current.pause();
      } else {
        this.audioRef.current.play();
      }
    };
  
    render() {
      return (
        <div>
          <audio
            ref={this.audioRef}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            src={music}
            loop={true}
          />
          <div onClick={this.togglePlay}>
          
          {this.state.playing ?  <img src={MusicPlayIcon} width={32}/>: <img src={MusicStopIcon} width={32}/> }
          </div>
       
           
        
        </div>
      );
    }
}

export default MusicPlayer;