import React, { Component } from "react";
import { message, Modal } from "antd";
import Chains from "../../libs/Chains";
import Web3 from "web3";
import EthCrypto from "eth-crypto";
import "./Wallet.css";
import { ToastContainer, toast } from "react-toastify";
import CharacterImages from "../../libs/Characters";
import "react-toastify/dist/ReactToastify.css";
import GameLib from "../../libs/GameLib";
import Logo from "../../assets/logo.png";
class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: "",
      currentChainId: "",
      score: [],
      showNewBurnWallet: false,
      showBurnWallet: false,
      burnWalletTab: 0,
      newBurnWallet: "",
      importPrivateKey: "",
      deposit: 0,
      showLoading: false,
      showNewPlayer: false,
      showRegister: true,
      myBurnWallet: null,
      player: "",
      characterIndex: 0,
      characterName: "",
    };
  }
  async componentDidMount() {
    const { ethereum } = window;

    var that = this;

    if (ethereum && ethereum.isMetaMask) {
      var currentAddress = await that.initAccount();
      var currentChainId = await that.initNetwork();

      window.ethereum.on("accountsChanged", async function () {
        window.location.reload();
      });
      window.ethereum.on("chainChanged", async function () {
        window.location.reload();
      });

      var burnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
      if (currentChainId != "") {
        if (burnWallet != null) {
          var web3 = new Web3(window.ethereum);
          var balance = await web3.eth.getBalance(burnWallet.address);
          burnWallet.balance = balance;
          this.setState({
            showBurnWallet: true,
            myBurnWallet: burnWallet,
          });

          //检查是否注册
          var gameLib = new GameLib(currentChainId);
          var playerState = await gameLib.getPlayerState(burnWallet.address);

          //没注册
          if (!playerState[1]) {
            this.setState({
              showRegister: true,
            });
          } else {
            this.setState({
              showRegister: false,
            });
          }
          console.log("playerState", playerState);
        } else {
          this.setState({
            showBurnWallet: true,
          });
        }
      }
    }
  }

  initAccount = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    var currentAddress = accounts[0] != undefined ? accounts[0] : "";

    this.setState({
      currentAddress: currentAddress,
    });

    return currentAddress;
  };

  initNetwork = async () => {
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var currentChainId = Chains.has(chainId) ? chainId : "";
    this.setState({
      currentChainId: currentChainId,
    });

    return currentChainId;
  };

  changeNetwork = async (chainId) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      this.setState({
        connectWalletModalIsOpen: false,
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [Chains.get(chainId).Network],
          });

          this.initNetwork();
        } catch (addError) {
          console.log(addError);
        }
      }
    }
  };

  handleConnect = async () => {
    if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
    } else {
      message.warning("Please install metamask and switch to Network.", 3);
    }
  };

  getShortAddress(longAddress) {
    console.log("longAddress", longAddress);
    let end = longAddress.length;
    let addressShort =
      longAddress.substring(0, 5) + "..." + longAddress.substring(end - 4, end);
    return addressShort;
  }

  closeModal = () => {
    this.setState({
      showNewBurnWallet: false,
      showNewPlayer: false,
    });
  };

  addWallet = () => {
    const newBurnWallet = EthCrypto.createIdentity();
    this.setState({
      newBurnWallet: newBurnWallet,
      showNewBurnWallet: true,
    });
  };

  changeBurnTab = (currentTab) => {
    this.setState({
      burnWalletTab: currentTab,
    });
  };

  generateBurnWallet = () => {
    const newBurnWallet = EthCrypto.createIdentity();
    this.setState({
      newBurnWallet: newBurnWallet,
    });
    console.log("newBurnWallet", newBurnWallet);
  };

  removeBurnWallet = async () => {
    localStorage.removeItem("BurnWallet");
    window.location.reload();
  };

  createNewBurnWallet = async () => {
    var newBurnWallet;
    if (this.state.burnWalletTab == 0) {
      newBurnWallet = this.state.newBurnWallet;
    } else {
      var web3 = new Web3(window.ethereum);
      var account = web3.eth.accounts.privateKeyToAccount(
        this.state.importPrivateKey
      );
      newBurnWallet = {
        address: account.address,
        privateKey: this.state.importPrivateKey,
      };
    }

    if (this.state.deposit == 0 || this.state.deposit == "") {
      message.error("Please deposit at least 0.01 eth for gas fee", 3);

      // localStorage.setItem("BurnWallet", JSON.stringify(newBurnWallet));
      // this.setState({
      //   showNewBurnWallet: false,
      // });

      // window.location.reload();
    } else {
      const response = await toast.promise(
        async () => {
   
          var web3 = new Web3(window.ethereum);

          var result = await web3.eth.sendTransaction({
            from: this.state.currentAddress,
            to: newBurnWallet.address,
            value: Web3.utils.toWei(this.state.deposit, "ether"),
          });
          localStorage.setItem("BurnWallet", JSON.stringify(newBurnWallet));
          this.setState({
            showNewBurnWallet: false,
          });

          window.location.reload();

       
        },
        {
          pending: { render: "pending", position: "top-center" },
          success: "Success",
          error: "Fail",
        }
      );
    }

    // this.writeToFile(newBurnWallet)
  };

  writeToFile(burnerWallet) {
    const fileData = JSON.stringify(burnerWallet);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = burnerWallet.address + ".json";
    link.href = url;
    link.click();
  }

  newPlayer = () => {
    this.setState({
      showNewPlayer: true,
    });
  };

  selectCharacterImage = (index) => {
    this.setState({
      characterIndex: index,
    });
  };

  createNewCharacter = async () => {
    var characterName = this.state.characterName;
    if (characterName == "") {
      message.error("Please give the character a name", 3);
    } else {
      const response = await toast.promise(
        async () => {
          var roleId = CharacterImages[this.state.characterIndex].id;

          var gameLib = new GameLib(this.state.currentChainId);

          var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
          gameLib.addBurnWallet(myBurnWallet);

          await gameLib.createPlayer(
            myBurnWallet.address,
            roleId,
            characterName
          );

          window.location.reload();
        },
        {
          pending: { render: "pending", position: "top-center" },
          success: "Success",
          error: "Fail",
        }
      );
    }
  };

  render() {
    return (
      <div className="WalletPannel">
        <ToastContainer></ToastContainer>
        <Modal
          title="New Player"
          open={this.state.showNewPlayer}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={null}
          mask={false}
        >
          <div className="CharacterTitle">Character Name</div>
          <input
            className="BurnWalletInput"
            placeholder="Character Name"
            onChange={(e) => {
              this.setState({
                characterName: e.target.value,
              });
            }}
          />
          <div className="CharacterTitle">Please select a character</div>
          <ul className="CharacterImages">
            {CharacterImages.map((item, index) => (
              <li
                className={
                  this.state.characterIndex == index
                    ? "CharacterImages_Sel"
                    : "CharacterImages_Nor"
                }
                key={index}
                onClick={this.selectCharacterImage.bind(this, index)}
              >
                {this.state.characterIndex == index ? (
                  <img src={item.body} className="CharacterHead" />
                ) : (
                  <img src={item.head} className="CharacterHead" />
                )}
              </li>
            ))}
          </ul>

          <div
            className="BurnWalletCreate"
            onClick={this.createNewCharacter.bind(this)}
          >
            Create
          </div>
        </Modal>
        <Modal
          title="Burner Wallet"
          open={this.state.showNewBurnWallet}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={null}
          mask={false}
        >
          {/* <div className="BurnWalletTab">
            <div
              className={
                this.state.burnWalletTab == 0
                  ? "BurnWalletTabSelect"
                  : "BurnWalletTabNormal"
              }
              onClick={this.changeBurnTab.bind(this, 0)}
            >
              New
            </div>
            <div
              className={
                this.state.burnWalletTab == 1
                  ? "BurnWalletTabSelect"
                  : "BurnWalletTabNormal"
              }
              onClick={this.changeBurnTab.bind(this, 1)}
            >
              Import
            </div>
          </div> */}

          {this.state.burnWalletTab == 0 ? (
            <div className="BurnWalletNew">
              <div className="BurnWalletNewInfo">
                <div>
                  <span>Address</span>
                  <br />
                  {this.state.newBurnWallet.address}
                </div>
                <div>
                  <span>Private Key</span>
                  <br />
                  {this.state.newBurnWallet.privateKey}
                </div>
              </div>
              <div
                className="BurnWalletGenerate"
                onClick={this.generateBurnWallet.bind(this)}
              >
                Regenerate
              </div>
            </div>
          ) : (
            <input
              className="BurnWalletInput"
              placeholder="Private Key"
              onChange={(e) => {
                this.setState({
                  importPrivateKey: e.target.value,
                });
              }}
            />
          )}

          <input
            type="number"
            className="BurnWalletInput"
            placeholder="Please deposit at least 0.01 eth for gas fee"
            onChange={(e) => {
              this.setState({
                deposit: e.target.value,
              });
            }}
          />
          <div className="BurnerWallerTip">
            Burner Wallet is a temporary wallet used for games, all your gas
            fees in the game will be paid by burner wallet, please back it up
            yourself.
          </div>
          <div
            className="BurnWalletCreate"
            onClick={this.createNewBurnWallet.bind(this)}
          >
            Create
          </div>

          <div hidden={!this.state.showLoading} className="BurnWalletLoading">
            Loading
          </div>
        </Modal>

        <div className="LoginLogo">
          <img src={Logo} width={164} />
          <p>A Fully Onchain Magical World</p>
        </div>

        <div
        // className={
        //   this.state.currentAddress != "" &&
        //   this.state.currentChainId != "" &&
        //   this.state.myBurnWallet != null &&
        //   this.state.player != ""
        //     ? "WalletPannelConnect"
        //     : "WalletPannel"
        // }
        >
          {this.state.currentAddress == "" ? (
            //是否连接钱包

            <div className="WalletBtn" onClick={this.handleConnect}>
              <div>Connect Wallet</div>
            </div>
          ) : (
            <div>
              <div className="WalletAddressConnect">
                <div>Main Wallet</div>
                <div>{this.state.currentAddress}</div>
                {/* <div>{this.getShortAddress(this.state.currentAddress)}</div> */}
              </div>

              {this.state.showBurnWallet ? ( //是否显示burnwallet
                <div className="WalletAddressConnect">
                  <div>Burner Wallet</div>
                  {this.state.myBurnWallet == null ? (
                    <div>
                      <div
                        onClick={this.addWallet.bind(this)}
                        className="AddBurnWalletBtn"
                      >
                        Add Burner Wallet
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="BurnWalletAddress">
                        {this.state.myBurnWallet.address}
                      </div>
                      <div>
                        Balance:
                        {Web3.utils.fromWei(this.state.myBurnWallet.balance)}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {this.state.currentChainId == "" ? (
                <div>
                  <div className="NetworkTitle">Select a Network</div>
                  <ul className="NetworkList">
                    {Array.from(Chains).map((item, index) => (
                      <li key={index}>
                        <div className="NetworkItem">
                          <div className="NetworkName">
                            {item[1].Network.chainName}
                          </div>
                          <a
                            href={item[1].Info}
                            className="NetworkInfo"
                            target="_blank"
                          >
                            {item[1].Network.chainName}&nbsp;Network Info
                          </a>
                        </div>
                        <div
                          className="NetworkButton"
                          onClick={this.changeNetwork.bind(this, item[0])}
                        >
                          Select
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <div className="WalletAddressConnect">
                    <div className="NetworkTitle">Network</div>

                    <div>
                      {Chains.has(this.state.currentChainId)
                        ? Chains.get(this.state.currentChainId).Network
                            .chainName
                        : ""}
                    </div>
                  </div>

                  {this.state.myBurnWallet != null ? (
                    this.state.showRegister ? (
                      <div
                        className="CreatePlayerButton"
                        onClick={this.newPlayer.bind(this)}
                      >
                        Create Player
                      </div>
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Wallet;
