import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./Game.css";
import MarketLand from "../../components/Lands/MarketLand/MarketLand";
import SpellLand from "../../components/Lands/SpellLand/SpellLand";
import Wallet from "../../components/Wallet/Wallet";
import GameLib from "../../libs/GameLib";
import Chains from "../../libs/Chains";
import MyBagIcon from "../../assets/ui/MyBagIcon.png";
import MyWalletIcon from "../../assets/ui/MyWalletIcon.png";
import CommunityIcon from "../../assets/ui/CommunityIcon.png";
import WeaponIcon from "../../assets/ui/Weapon.png"
import MusicPlayer from "../../components/MusicPlayer/MusicPlayer";
import { message, Modal, Drawer } from "antd";
import MyWallet from "../../components/MyWallet/MyWallet";
import MyBag from "../../components/MyBag/MyBag";
import MarketPlace from "../../components/MarketPlace/MarketPlace";
import Player from "../../components/Player/Player";
import Spell from "../../components/Spell/Spell";
import MainTown from "../../components/Lands/MainTown/MainTown";
import BattleModal from "react-modal";
import Battle from "../../components/Battle/Battle";
import GameLogo from "../../assets/logo.png";
import SandLand from "../../components/Lands/SandLand/SandLand";
import SnowLand from "../../components/Lands/SnowLand/SnowLand";
import Craft from "../../components/Craft/Craft";
import Dialogs from "../../libs/Dialogs";
import Community from "../../components/Community/Community";
class Game extends Component {
  constructor(props) {
    super(props);

    this.childRef = React.createRef();
    this.state = {
      showWallet: true,
      showModal: false,
      showDrawer: false,
      drawerType: "",
      showBattleModal: false,
      playerKey: Date.now(),
      dialogKey: "",
      battleId: 0,
    };
  }

  async componentDidMount() {
    const { ethereum } = window;

    if (ethereum && ethereum.isMetaMask) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      var currentAddress = accounts[0] != undefined ? accounts[0] : "";
      var chainId = await window.ethereum.request({ method: "eth_chainId" });
      var currentChainId = Chains.has(chainId) ? chainId : "";
      var myBurnWallet = localStorage.getItem("BurnWallet");

      var playerState = false;
      if (myBurnWallet != null && currentChainId != "") {
        var gameLib = new GameLib(chainId);
        myBurnWallet = JSON.parse(myBurnWallet);
        playerState = await gameLib.getPlayerState(myBurnWallet.address);
      }

      if (
        currentAddress == "" ||
        currentChainId == "" ||
        myBurnWallet == null ||
        !playerState[1]
      ) {
        this.setState({
          showWallet: true,
        });
      } else {
        this.setState({
          showWallet: false,
        });
      }
    } else {
      this.setState({
        showWallet: true,
      });
    }
  }

  handleShowModal = (modalType) => {
    this.setState({
      modalType: modalType,
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  closeDrawer = () => {
    this.setState({
      showDrawer: false,
    });
  };

  handleShowDrawer = (drawerType) => {
    this.setState({
      showDrawer: true,
      drawerType: drawerType,
    });
    console.log(1);
  };

  handleBattleModal = (showBattleModal) => {
    console.log(showBattleModal);
    this.setState({
      showBattleModal: showBattleModal,
    });
  };

  handleUseProps = () => {
    this.setState({
      playerKey: Date.now(),
    });
  };

  handleModalOperate = (op) => {
    switch (op.type) {
      case "dialog":
        this.setState({
          modalType: "Dialog",
          dialogKey: op.value,
          showModal: true,
        });
        break;
      case "battle":
        this.setState({
          battleId: op.value,
          showBattleModal: true,
        });
        break;
    }
  };

  render() {
    return (
      <div>
        <img src={GameLogo} className="GameLogo" width={140} />

        <Drawer
          destroyOnClose={true}
          height={650}
          title={this.state.drawerType}
          placement="bottom"
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
        >
          {this.state.drawerType == "Market" ? (
            <MarketPlace></MarketPlace>
          ) : this.state.drawerType == "Spells" ? (
            <Spell></Spell>
          ) : (
            <Craft></Craft>
          )}
        </Drawer>

        <BattleModal
          isOpen={this.state.showBattleModal}
          ariaHideApp={false}
          style={{
            overlay: {
              background: "rgba(0, 0, 0, 0.93)",
              zIndex: 101,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "0",
              border: "0",
            },
          }}
        >
          <Battle
            battleId={this.state.battleId}
            handleBattleModal={this.handleBattleModal}
            handleUseProps={this.handleUseProps}
          ></Battle>
        </BattleModal>

        <Modal
          title={this.state.modalType}
          open={this.state.showModal}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={null}
        >
          {this.state.modalType == "My Wallet" ? (
            <MyWallet></MyWallet>
          ) : this.state.modalType == "Inventory" ? (
            <MyBag handleUseProps={this.handleUseProps}></MyBag>
          ) : this.state.modalType == "Dialog" ? (
            <div className="DialogModal">
              <div>
                <img src={Dialogs.get(this.state.dialogKey).head} />
              </div>

              <div className="DialogModalContent">
                <p>{Dialogs.get(this.state.dialogKey).title}</p>
                <p className="typewriter monospace no-caret">
                  {Dialogs.get(this.state.dialogKey).content}
                </p>
              </div>
            </div>
          ) : (
            this.state.modalType=="Community"?(<Community></Community>):( <div></div>)
           
          )}
        </Modal>

        {this.state.showWallet ? (
          <Wallet></Wallet>
        ) : (
          <div className="World">
            <div className="LeftBar">
              <Player key={this.state.playerKey}></Player>

              <ul>
                <li onClick={this.handleShowModal.bind(this, "My Wallet")}>
                  <img src={MyWalletIcon} width={32} />
                  <p>Wallet</p>
                </li>
                <li onClick={this.handleShowModal.bind(this, "Inventory")}>
                  <img src={MyBagIcon} width={32} />
                  <p>Inventory</p>
                </li>
                <li>
                  <MusicPlayer></MusicPlayer>
                  <p>Music</p>
                </li>
                <li onClick={this.handleShowModal.bind(this, "Community")}>
                  <img src={CommunityIcon} width={32} />
                  <p>Community</p>
                </li>
              </ul>
            </div>

            <TransformWrapper
              minPositionX={100}
              initialPositionY={40}
              centerZoomedOut={false}
              limitToBounds={false}
              minScale={0.8}
              maxScale={3}
              doubleClick={{
                disabled: true,
              }}
              wheel={{
                wheelDisabled: true,
                smoothStep: 0.005,
              }}
            >
              <TransformComponent>
                <div className="Map">
                  <MarketLand handleShowDrawer={this.handleShowDrawer} />
                  <MainTown
                    handleModalOperate={this.handleModalOperate}
                  ></MainTown>
                  <SpellLand
                    handleShowDrawer={this.handleShowDrawer}
                  ></SpellLand>
                </div>
                <div className="Map">
                  <SandLand handleShowDrawer={this.handleShowDrawer}></SandLand>
                  <SnowLand  handleModalOperate={this.handleModalOperate}></SnowLand>
       
                </div>
              </TransformComponent>
            </TransformWrapper>
          </div>
        )}
      </div>
    );
  }
}

export default Game;
