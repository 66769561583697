import React, { Component } from "react";
import Props from "../../libs/Props";
import GameLib from "../../libs/GameLib";
import "./Battle.css";
import { Popover, Modal } from "antd";
import SpellImage from "../../assets/spells/effect/FireEffect.gif";
import Monsters from "../../libs/Monsters";
import CharacterImages from "../../libs/Characters";
import Spells from "../../libs/Spells";
import MyBag from "../MyBag/MyBag";
import { ToastContainer, toast } from "react-toastify";

class Battle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myGameProps: [],
      monsterId: props.battleId,
      playerData: "",
      mySpells: [],
      monsterData: "",
      battleReward: [],
      modalType: "Inventory",
      showModal: false,
      showEffect: false,
      effectImage: SpellImage,
      battleStart: false,
    };
  }

  async componentDidMount() {
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var gameLib = new GameLib(chainId);

    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));

    gameLib.addBurnWallet(myBurnWallet);

    this.setState({
      gameLib: gameLib,
      myBurnWallet: myBurnWallet,
    });

    await this.getPlayerData(gameLib, myBurnWallet);
    await this.getPlayerSpells(gameLib, myBurnWallet);
    await this.getMonster(gameLib);
    await this.getBattleReward(gameLib);
  }

  leaveBattle = async() => {
   
    await this.props.handleUseProps();
    this.props.handleBattleModal(false);
  };

  getPlayerData = async (gameLib, myBurnWallet) => {
    var playerData = await gameLib.getPlayerData(myBurnWallet.address);

    var playerState = await gameLib.getPlayerState(myBurnWallet.address);

    var avater;
    for (var item of CharacterImages) {
      if (playerData[0] == item.id) {
        avater = item.body;
      }
    }

    var equipment=await gameLib.getEquipment(myBurnWallet.address);

    var craftATK=0
    var craftDEF=0
    if(equipment[0]){
      var craftAttribute=await gameLib.getCraftAttributes(equipment[1])
      craftATK=craftAttribute[0]
      craftDEF=craftAttribute[1]
    }

    this.setState({
      playerData: {
        playerId: playerState[0],
        avater: avater,
        roldId: playerData[0],
        name: playerData[1],
        hp: playerData[2],
        mp: playerData[3],
        matk: parseInt(playerData[4])+parseInt(craftATK),
        def: parseInt(playerData[5])+parseInt(craftDEF),
      },
    });
  };

  getPlayerSpells = async (gameLib, myBurnWallet) => {
    var playerState = await gameLib.getPlayerState(myBurnWallet.address);
    var playerId = playerState[0];
    var playerSpells = await gameLib.getCharacterSpells(0, playerId);

    this.setState({
      mySpells: playerSpells,
    });
  };



  getMonster = async (gameLib) => {
    var monsterData = await gameLib.getMonster(this.state.monsterId);
    this.setState({
      monsterData: {
        role:monsterData.role,
        name:monsterData.name,
        def:monsterData.def,
        hp:monsterData.hp,
        matk:monsterData.matk,
      },
    });
    console.log("monsterData", monsterData);
  };

  getBattleReward = async (gameLib) => {

    
    console.log("this.state.monsterId",this.state.monsterId)
    var battleReward = await gameLib.getBattleReward(this.state.monsterId);

   

    this.setState({
      battleReward: battleReward,
    });
    console.log("reward", battleReward);
  };

  startBattle = async () => {

    const response = await toast.promise(
      async () => {

        var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
        this.state.gameLib.addBurnWallet(myBurnWallet);
        await this.state.gameLib.createBattle(
          this.state.monsterId,
          myBurnWallet.address
        );
    
    
        // var myBattle=await this.state.gameLib.getBattle(this.state.playerData.playerId)
        // console.log("myBattle",myBattle)
    
        this.setState({
          battleStart: true,
        });
    
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
);


  };

  useSpell = async (spellId) => {



    if(this.state.battleStart && this.state.playerData.mp>=Spells.get(spellId).metadata.costMP && this.state.playerData.hp!=0  && this.state.monsterData.hp!=0){
      var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));

      this.setState({
        showEffect: true,
        effectImage: Spells.get(spellId).effect,
      });
      await this.state.gameLib.attackMonster(spellId, myBurnWallet.address);
  
      await this.getPlayerData(this.state.gameLib, this.state.myBurnWallet);
  
  
      var myBattle=await this.state.gameLib.getBattle(this.state.playerData.playerId)

      console.log("myBattle",myBattle)

      var monsterData=this.state.monsterData;
  
      monsterData.hp=myBattle.MonsterHP;
      monsterData.matk=myBattle.MonsterMATK;
      monsterData.def=myBattle.MonsterDEF;
      this.setState({
        showEffect: false,
        monsterData:monsterData
      })
    }



   
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleUseProps = async () => {
    await this.getPlayerData(this.state.gameLib, this.state.myBurnWallet);
  };

  render() {
    return (
      <div className="Battle">
        <ToastContainer></ToastContainer>
        <Modal
          title={this.state.modalType}
          open={this.state.showModal}
          destroyOnClose={true}
          onCancel={this.closeModal}
          footer={null}
          mask={false}
        >
          <MyBag handleUseProps={this.handleUseProps}></MyBag>
        </Modal>

        {this.state.playerData != "" ? (
          <div className="BattlePlayer">
            <div className="BattlePlayerImage">
              <img src={this.state.playerData.avater} height={96} />
            </div>
          

            <div className="BattlePlayerState">
              <div>HP:{this.state.playerData.hp} </div>
              <div>MP:{this.state.playerData.mp} </div>
              <div>ATK:{this.state.playerData.matk} </div>
              <div>DEF:{this.state.playerData.def} </div>
            </div>

            <div className="BattelSpellsTitle">Spells</div>
            <ul className="BattleSpells">
              {this.state.mySpells.map((item, index) => (
                <li key={index} onClick={this.useSpell.bind(this, item)}>
                  <Popover
                    trigger="hover"
                    content={
                      <div>
                        <p>{Spells.get(item).metadata.description}</p>
                        <p>Spend HP:{Spells.get(item).metadata.costHP}</p>
                        <p>Spend MP:{Spells.get(item).metadata.costMP}</p>
                        <p>Restore HP:{Spells.get(item).metadata.boostHP}</p>
                        <p>Increase ATK:{Spells.get(item).metadata.boostMATK}</p>
                        <p>Increase DEF:{Spells.get(item).metadata.boostDEF}</p>
                        <p>Decrease ATK:{Spells.get(item).metadata.reduceMATK}</p>
                        <p>Decrease DEF:{Spells.get(item).metadata.reduceDEF}</p>
                      </div>
                    }
                  >
                    <img src={Spells.get(item).image} width={48} />
                  </Popover>
                </li>
              ))}
            </ul>
            {this.state.battleStart?(       <div>
              Click the spell to attack
            </div>   ):(<div></div>)}
           
            {/* <div onClick={this.showModal.bind(this)}>My Bag</div> */}
          </div>
        ) : (
          <div></div>
        )}

        <div className="BattleInfo">

          {/* win */}
          {this.state.battleStart&&this.state.monsterData.hp==0?(<div className="battleVictory">Victory</div>):(<div></div>)}

           {/* lost */}
          {this.state.battleStart&&this.state.playerData.hp==0&&this.state.monsterData.hp>0?(<div className="battleDefeat">Defeat</div>):(<div></div>)}

          {this.state.battleStart?(<div></div>):(    <div className="BattleStart" onClick={this.startBattle.bind(this)}>
            Battle
          </div>)}
      

          <div className="BattleLeave" onClick={this.leaveBattle.bind(this)}>
            Leave
          </div>
        </div>
        {this.state.monsterData != "" ? (
          <div className="BattleMonster">
            {this.state.showEffect ? (
              <img src={this.state.effectImage} className="BattleSpellEffect" />
            ) : (
              <span></span>
            )}
              <div className="BattlePlayerImage">
            <img
              className="flipped"
              src={Monsters.get(parseInt(this.state.monsterData.role)).image}
              height={96}
            />
           </div>
            <div className="BattleMonsterState">
              <div>{this.state.monsterData.name}</div>
              <div>HP:{this.state.monsterData.hp}</div>
              <div>ATK:{this.state.monsterData.matk}</div>
              <div>DEF:{this.state.monsterData.def}</div>
            </div>

            <div className="BattleRewardsTittle">Rewards</div>
            <ul className="BattleReward">
              {this.state.battleReward.map((item, index) => (
                <li key={index}>
                  <img src={Props.get(parseInt(item[0][0])).image} width={48} />
                  <div className="BattleRewardAmount">{item[1]}</div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default Battle;
