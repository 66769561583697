import Goblin1 from "../assets/gif/mainland/Goblin1.gif"
import Goblin2 from "../assets/gif/mainland/Goblin2.gif"
import Goblin3 from "../assets/gif/mainland/Goblin3.gif"
import Ghost from "../assets/gif/snow/Ghost.gif"
import Skeleton from "../assets/gif/snow/Skeleton.gif"
const Monsters = new Map([
  [
    0,
    {
      role: 0,
      image:Goblin1,
      name: "Goblin",
      hp: 100,
      mp: 100,
      matk: 8,
      def: 8,
      propBundle: [[0,20],[1,2],[6,2]],
    }
  ],
  [
    1,
    {
      role: 1,
      image:Goblin2,
      name: "Goblin",
      hp: 100,
      mp: 100,
      matk: 15,
      def: 15,
      propBundle: [[0,50],[1,6],[6,4]],
    }
  ],
  [
    2,
    {
      role: 2,
      image:Goblin3,
      name: "Goblin",
      hp: 100,
      mp: 100,
      matk: 20,
      def: 20,
      propBundle: [[0,80],[1,8],[6,5]],
    }
  ], [
    3,
    {
      role: 3,
      image:Ghost,
      name: "Ghost",
      hp: 100,
      mp: 100,
      matk: 25,
      def: 25,
      propBundle: [[0,200],[5,1]],
    }
  ],
  [
    4,
    {
      role: 4,
      image:Skeleton,
      name: "Skeleton",
      hp: 100,
      mp: 100,
      matk: 25,
      def: 15,
      propBundle: [[0,200],[1,20]],
    }
  ],
  
]);

export default Monsters;
