import Head1 from "../assets/characters/head1.png";
import Head2 from "../assets/characters/head2.png";
import Head3 from "../assets/characters/head3.png";
import Head4 from "../assets/characters/head4.png";
import Head5 from "../assets/characters/head5.png";
import Head6 from "../assets/characters/head6.png";
import Head7 from "../assets/characters/head7.png";
import Head8 from "../assets/characters/head8.png";
import Head9 from "../assets/characters/head9.png";
import Head10 from "../assets/characters/head10.png";


import Body1 from "../assets/characters/body1.gif";
import Body2 from "../assets/characters/body2.gif";
import Body3 from "../assets/characters/body3.gif";
import Body4 from "../assets/characters/body4.gif";
import Body5 from "../assets/characters/body5.gif";
import Body6 from "../assets/characters/body6.gif";
import Body7 from "../assets/characters/body7.gif";
import Body8 from "../assets/characters/body8.gif";
import Body9 from "../assets/characters/body9.gif";
import Body10 from "../assets/characters/body10.gif";


const CharacterImages = [
  { id: 0, head: Head1, body: Body1 },
  { id: 1, head: Head2, body: Body2 },
  { id: 2, head: Head3, body: Body3 },
  { id: 3, head: Head4, body: Body4 },
  { id: 4, head: Head5, body: Body5 },
  { id: 5, head: Head6, body: Body6 },
  { id: 6, head: Head7, body: Body7 },
  { id: 7, head: Head8, body: Body8 },
  { id: 8, head: Head9, body: Body9 },
  { id: 9, head: Head10, body: Body10 },
];



export default CharacterImages;
