import { useEffect } from "react";
import "./MarketLand.css";
import Market from "../../../assets/lands/market.png";
import Witch from "../../../assets/gif/market/witch.gif";
import ChatMarket from "../../../assets/ui/ChatMarket.svg";
function MarketLand(props) {
  useEffect(() => {});

  const showMarkertDrawer = () => {
    props.handleShowDrawer("Market");
  };
  return (
    <div className="MarketLand">
      <div className="MarketLandWitch" onClick={showMarkertDrawer}>
        <div className="LandChat">
          <img src={ChatMarket} width={12} />
          <p>Market</p>
        </div>
        <img src={Witch} width={20} />
      </div>

      <img src={Market} />
    </div>
  );
}

export default MarketLand;
