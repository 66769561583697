const Chains = new Map([
  // [
  //   "0x539",
  //   {
  //     Network: {
  //       chainId: "0x539",
  //       chainName: "localhost",
  //       rpcUrls: ["http://127.0.0.1:7545"],
  //       nativeCurrency: {
  //         name: "ETH",
  //         symbol: "ETH",
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ["http://127.0.0.1:7545"],
  //     },
  //     Burn:{
  //       url:"http://127.0.0.1:7545"
  //     },
  //     Contracts: {
  //       World:"0x850366340a57C7bdEEB09B9056547df1a4f90ab7",
  //       GameProps:"0xFF2CEB1600d80482e5Fd2447610Eb93d5660f97C",
  //       Craft:"0x6A681E36232796183C8eCd5eC9121AaD2E2B0271",
  //       Market:"0xdAFd2680ecF3D734Cc6e65BbA62f764b9aC78D04",
  //       GameManage:"0xD2669C09aC14F4eB46dBbfda6A752425Bdf62464",
  //       Play: "0xc2768402461F41f2031588C4854B1dB929E54eC8"
  //     },
  //     Info:""
  //   },
  // ],
  [   "0x4269",
  {
    Network: {
      chainId: "0x4269",
      chainName: "Redstone Holesky",
      rpcUrls: ["https://rpc.holesky.redstone.xyz"],
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      blockExplorerUrls: ["https://explorer.holesky.redstone.xyz"],
    },
    Burn:{
      url:"https://rpc.holesky.redstone.xyz"
    },
    Contracts: {
      World:"0x1eEcCb8451073784587D9dEDEDbdd36408e68BAa",
      GameProps:"0xC856bD4A0B35FeB9Ac1aDa0D3E64FAE010a760ec",
      Craft:"0x601081E9D7EE32Fa84E592044e22F06884B26D1f",
      Market:"0xe8d04ee9d0ba6c17C69fB6367C90890cBaDdeA52",
      GameManage:"0xE724F39A96f4cD8994899fc7b945881c346F32DF",
      Play: "0x8CF21C7994287defBb75E7f5837e87554c449625"
    },
    Info:"https://redstone.xyz/docs/network-info"
  },
  ]

]);

export default Chains;
