import { useEffect } from "react";
import "./SandLand.css"
import sand from "../../../assets/lands/sand.png";
import blacksmith from "../../../assets/gif/sandland/CraftBuilding.gif"
import ChatHarmer from "../../../assets/ui/ChatHarmer.svg"

function SandLand(props) {
  useEffect(() => {});

  const showMarkertDrawer=()=>{
    props.handleShowDrawer("Craft")

  }
  return (
    <div className="SandLand" >


      <div className="SandLandCraft" onClick={showMarkertDrawer}>
        <div className="LandChat">
          <img src={ChatHarmer} width={12} />
          <p>Craft</p>
        </div>
        <img src={blacksmith} width={32} />
      </div>



        <img src={sand} width={550}/>
     
    </div>
  );
}

export default SandLand;
