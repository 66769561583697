import Game from "./pages/Game/Game";
import Manage from "./pages/Manage/Manage";
import Craft from "./components/Craft/Craft"
import Community from "./components/Community/Community"
const routes = [
  {
    path: "/",
    element: <Game/>,
  },  {
    path: "/Manage",
    element: <Manage/>,
  },  {
    path: "/Craft",
    element: <Craft/>,
  },  {
    path: "/Community",
    element: <Community/>,
  }
  
];
export default routes;