import React, { Component } from "react";
import "./Community.css";

class Community extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="Community">
        <p>
          Hey Wizard, thanks for playing WizWorld, it's under build and I'm
          trying to add more interesting features, if you have any suggestions
          please send me a message on twitter.
        </p>

        {/* <a href="https://discord.gg/JxcvHTbWMj" target="_blank">[Discord]</a> */}
        <a href="https://twitter.com/wizworld_xyz" target="_blank">
          [Twitter]
        </a>
      </div>
    );
  }
}

export default Community;
