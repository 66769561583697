import React, { Component } from "react";
import "./MarketPlace.css";
import Props from "../../libs/Props";
import AddIcon from "../../assets/ui/AddIcon.svg";
import ReduceIcon from "../../assets/ui/ReduceIcon.svg";
import GameLib from "../../libs/GameLib";
import { Input, Popover } from "antd";
import Scrollbar from "react-scrollbars-custom";
import GoldAnimate from "../../assets/ui/GoldAnimate.gif";
import GoldCoin from "../../assets/ui/Gold.png";
import { ToastContainer, toast } from "react-toastify";
class MarketPlace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalAmount: 0,
      buyItems: [],
      marketItems: [],
      itemMax: [],
      tabHeaderList: ["Buy", "Sell"],
      currentTabIndex: 0,
      currentChainId: "",
      goldBalance: 0,
    };
  }

  async componentDidMount() {
    await this.getMarket();
    await this.getGoldBalance();
  }

  add = (index) => {
    //buy
    if (this.state.currentTabIndex == 0) {
      var buyItems = this.state.buyItems;
      buyItems[index]++;
      this.setState({
        buyItems: buyItems,
      });
      this.updateTotal();
    } else {
      //sell
      var buyItems = this.state.buyItems;
      if (buyItems[index] < this.state.itemMax[index]) {
        buyItems[index]++;
        this.setState({
          buyItems: buyItems,
        });
        this.updateTotal();
      }
    }
  };

  reduce = (index) => {
    var buyItems = this.state.buyItems;

    if (buyItems[index] != 0) {
      buyItems[index]--;
      this.setState({
        buyItems: buyItems,
      });
    }

    this.updateTotal();
  };

  updateTotal = () => {
    var buyItems = this.state.buyItems;

    var totalAmount = 0;
    var marketItems = this.state.marketItems;
    for (var i = 0; i < marketItems.length; i++) {
      totalAmount += Props.get(marketItems[i]).price * buyItems[i];
    }

    this.setState({
      totalAmount: totalAmount,
    });
  };

  changeItemAmount = (index, e) => {
    if (this.state.currentTabIndex == 0) {
      var buyItems = this.state.buyItems;
      buyItems[index] = e.target.value;
      this.setState({
        buyItems: buyItems,
      });
      this.updateTotal();
    } else {
      if (e.target.value > this.state.itemMax[index]) {
        console.log("over max");
      } else {
        var buyItems = this.state.buyItems;
        buyItems[index] = e.target.value;
        this.setState({
          buyItems: buyItems,
        });
        this.updateTotal();
      }
    }
  };

  changeTab = async (index) => {
    this.setState({
      currentTabIndex: index,
      totalAmount: 0,
      buyItems: [],
    });

    if (index == 0) {
      this.getMarket();
    } else {
      this.getMyBag();
    }
  };

  checkout = async () => {


    const response = await toast.promise(
      async () => {


    

    var buyItems = this.state.buyItems;
    var marketItems = this.state.marketItems;
    var buyArray = [];
    for (var i = 0; i < buyItems.length; i++) {
      if (buyItems[i] != 0) {
        buyArray.push([marketItems[i], buyItems[i]]);
      }
    }

    var gameLib = new GameLib(this.state.currentChainId);

    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    gameLib.addBurnWallet(myBurnWallet);

    if (this.state.currentTabIndex == 0) {
      await gameLib.buy(buyArray, myBurnWallet.address);
    } else {
      await gameLib.sell(buyArray, myBurnWallet.address);
      await this.getMyBag();
    }

    await this.getGoldBalance();

    this.setState({
      totalAmount: 0,
      buyItems: new Array(Array.from(Props.keys()).length - 1).fill(0),
    });

  },
  {
    pending: { render: "pending", position: "top-center" },
    success: "Success",
    error: "Fail",
  }
);

  };

  getMarket = async () => {
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var marketIds = Array.from(Props.keys());
    marketIds.shift();
    this.setState({
      marketItems: marketIds,
      buyItems: new Array(marketIds.length).fill(0),
      currentChainId: chainId,
    });
  };

  getGoldBalance = async () => {
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var gameLib = new GameLib(chainId);
    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    var goldBalance = await gameLib.getGamePropsBatch(
      [myBurnWallet.address],
      [0]
    );
    this.setState({
      goldBalance: goldBalance,
    });
  };

  getMyBag = async () => {
    var batchIds = Array.from(Props.keys());
    batchIds.shift();

    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    var batchAddres = new Array(batchIds.length).fill(myBurnWallet.address);

    const chainId = this.state.currentChainId;
    var gameLib = new GameLib(chainId);

    var balanceBatch = await gameLib.getGamePropsBatch(batchAddres, batchIds);

    var marketIds = [];
    var itemMax = [];
    for (var i = 0; i < balanceBatch.length; i++) {
      if (balanceBatch[i] != 0) {
        marketIds.push(batchIds[i]);
        itemMax.push(balanceBatch[i]);
      }
    }

    console.log("marketIds", marketIds);

    this.setState({
      marketItems: marketIds,
      buyItems: new Array(marketIds.length).fill(0),
      itemMax: itemMax,
    });
  };

  render() {
    return (
      <div>
          <ToastContainer></ToastContainer>
          <div className="MarketContainer">
            <div className="MarketHeader">
              <div className="tabs">
                {this.state.tabHeaderList.map((item, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name="radio"
                      checked={
                        this.state.currentTabIndex == index ? true : false
                      }
                      value={index}
                      onChange={this.changeTab.bind(this, index)}
                    />
                    <span>{item}</span>
                  </label>
                ))}
              </div>
              <div className="marketBalance">
                <img src={GoldCoin} width={18} />

                <p>Balance:{this.state.goldBalance}</p>
              </div>
            </div>

              <Scrollbar style={{ height: 410 }}>
                {this.state.marketItems.length!=0?(
                  <ul className="Market">
                  {this.state.marketItems.map((item, index) => (
                    <li key={index}>
                      <div className="MarketItemImageAndInfo">
                        <img
                          src={Props.get(item).image}
                          width="100%"
                          className="MarketItemImage"
                        />
                        <div className="MarketItemInfo">
                          <Popover
                            trigger="hover"
                            content={
                              <div>
                                <p>{Props.get(item).metadata.description}</p>

                                {Props.get(item).metadata.boostMATK == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>
                                    ATK:+{Props.get(item).metadata.boostMATK}
                                  </p>
                                )}
                                {Props.get(item).metadata.boostDEF == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>
                                    DEF:+{Props.get(item).metadata.boostDEF}
                                  </p>
                                )}
                                {Props.get(item).metadata.boostHP == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>HP:+{Props.get(item).metadata.boostHP}</p>
                                )}
                                {Props.get(item).metadata.boostMP == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>MP:+{Props.get(item).metadata.boostMP}</p>
                                )}
                                {Props.get(item).metadata.restoreHP == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>
                                    HP:+{Props.get(item).metadata.restoreHP}
                                  </p>
                                )}
                                {Props.get(item).metadata.restoreMP == 0 ? (
                                  <p></p>
                                ) : (
                                  <p>
                                    MP:+{Props.get(item).metadata.restoreMP}
                                  </p>
                                )}
                              </div>
                            }
                            title={Props.get(item).metadata.name}
                          >
                            <div className="MarketItemName">
                              {Props.get(item).metadata.name}
                            </div>
                          </Popover>

                          <div className="MarketItemPrice">
                            <img src={GoldAnimate} />
                            <p>{Props.get(item).price}</p>
                          </div>
                        </div>
                      </div>

                 

                      {this.state.currentTabIndex == 1 ? (
                        <div className="MarketItemMax">
                          My Balance:{this.state.itemMax[index]}
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div className="MarketItemOperate">
                        <div
                          onClick={this.add.bind(this, index)}
                          className="MarketItemOperateAdd"
                        >
                          <img src={AddIcon} width={18} />
                        </div>
                        <div>
                          <input
                            className="MarketItemOperateInput"
                            type="number"
                            value={this.state.buyItems[index] || 0}
                            onChange={this.changeItemAmount.bind(this, index)}
                          />
                        </div>
                        <div
                          onClick={this.reduce.bind(this, index)}
                          className="MarketItemOperateAdd"
                        >
                          <img src={ReduceIcon} width={18} />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                ):(
                  <div></div>
                )}
                
              </Scrollbar>
           

            <div className="MarketFooter">
              <div className="MarketTotal">
                Total:
                <span className="MarketTotalCoin">
                  {this.state.totalAmount}
                </span>{" "}
                Gold Coin
              </div>
              <div
                className="MarketCheckout"
                onClick={this.checkout.bind(this)}
              >
                {this.state.tabHeaderList[this.state.currentTabIndex]}
              </div>
            </div>
          </div>
      
      </div>
    );
  }
}

export default MarketPlace;
