import React, { Component } from "react";
import Web3 from "web3";
import GamePropsABI from "../../libs/abi/GameProps.json";
import MarketPlaceABI from "../../libs/abi/Market.json";
import GameManageABI from "../../libs/abi/GameManage.json";
import WorldABI from "../../libs/abi/World.json";
import Chains from "../../libs/Chains";
import Props from "../../libs/Props";
import Spells from "../../libs/Spells";
import Monsters from "../../libs/Monsters";

class Manage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // //localhost
      // components: {
      //   Character: "0x43Ce6255728Ef25a56c6204Df6c2FDAF9213013B",
      //   CharacterSpell: "0xb1089f6f50aE541A7412D90f86753649fF0b068e",
      //   PlayerState: "0xC6361D87f48ccdb72C2df32DF94BcaB38a82378c",
      //   PropBundle: "0x91C50E4C98078d710D3e483b2314aCdB9d767936",
      //   Spell: "0xa9Fe992985192ea7cc9Ab0c59B19b5Bf792586bb",
      //   Battle: "0x665d3c68FCBD5D530F1690c211670FCD5de8DAbb",
      //   Equipment: "0x069DAd37eeCA279dA46Ac5b874f93186ecd28B02",
      // },
      // systems: {
      //   Market: "0xdAFd2680ecF3D734Cc6e65BbA62f764b9aC78D04",
      //   GameManage: "0xD2669C09aC14F4eB46dBbfda6A752425Bdf62464",
      //   Play: "0x125a17a41f04013BbB4D43A8162d1c92eBe1C6f3",
      // },
      components: {
        Character: "0xE036C84EEbD434738d2142F0E35B0cF11ba9D2F5",
        CharacterSpell: "0x9D7415f997fFEB9206dD371A8d6f06649dc6e05b",
        PlayerState: "0xF293EddCBD3719E2ac2E0F47728fde3BF3126Edd",
        PropBundle: "0x9e26feFdD38A340DFCF0FBc37EBdf9c5a23B7650",
        Spell: "0x5965cE4460D730c05032912Ec2531105907FD206",
        Battle: "0x79acAa96fe61137721926e5DCF9009140FfAA286",
        Equipment: "0xF6e6100E1D39E203B14893Eb9D5a87744BC0fdC8",
      },
      systems: {
        Market: "0xe8d04ee9d0ba6c17C69fB6367C90890cBaDdeA52",
        GameManage: "0xE724F39A96f4cD8994899fc7b945881c346F32DF",
        Play: "0x8CF21C7994287defBb75E7f5837e87554c449625",
      },
    };
  }

  async componentDidMount() {
    var web3 = new Web3(window.ethereum);

    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    const chainId = await window.ethereum.request({ method: "eth_chainId" });

    const chain = Chains.get(chainId);

    var gamePropsContract = new web3.eth.Contract(
      GamePropsABI,
      chain.Contracts.GameProps
    );

    var marketContract = new web3.eth.Contract(
      MarketPlaceABI,
      chain.Contracts.Market
    );

    var gameManageContract = new web3.eth.Contract(
      GameManageABI,
      chain.Contracts.GameManage
    );

    var WorldContract = new web3.eth.Contract(WorldABI, chain.Contracts.World);

    this.setState({
      currentAddress: accounts[0],
      gamePropsContract: gamePropsContract,
      marketContract: marketContract,
      gameManageContract: gameManageContract,
      WorldContract: WorldContract,
    });
  }

  createAsset = async () => {
    var result = await this.state.gamePropsContract.methods
      .addAsset([
        [
          "Green Gem",
          "https://bafybeieuvhuipmkm26z2zsstje6uaxm4gexmi64gyx7oq3pzd67yuwm5ha.ipfs.nftstorage.link/GreenGem.png",
          "Material for strengthening props",
          3,
          0,
          1,
          0,
          0,
          0,
          0,
        ],
      ])
      .send({ from: this.state.currentAddress })
      .on("transactionHash", function (hash) {
        console.info(hash);
        // return hash;
      })
      .on("receipt", function (receipt) {
        return receipt;
      });
    console.log("reuslt", result);
  };

  createAllSpell = async () => {
    var SpellsArray = Array.from(Spells);
    var SpellsParam = [];

    for (var i = 0; i < SpellsArray.length; i++) {
      SpellsParam.push([
        SpellsArray[i][1].metadata.name,
        SpellsArray[i][1].metadata.description,
        SpellsArray[i][1].metadata.costHP,
        SpellsArray[i][1].metadata.costMP,
        SpellsArray[i][1].metadata.boostHP,
        SpellsArray[i][1].metadata.boostMATK,
        SpellsArray[i][1].metadata.boostDEF,
        SpellsArray[i][1].metadata.reduceMATK,
        SpellsArray[i][1].metadata.reduceDEF,
        SpellsArray[i][1].metadata.summom,
        SpellsArray[i][1].metadata.propBundle,
      ]);
    }

    this.state.gameManageContract.methods
      .createSpell(2, SpellsParam)
      .send({ from: this.state.currentAddress });
  };

  createAllAsset = async () => {
    var PropsArray = Array.from(Props);
    var PropsParams = [];
    for (var i = 0; i < PropsArray.length; i++) {
      PropsParams.push([
        PropsArray[i][1].metadata.name,
        PropsArray[i][1].metadata.image,
        PropsArray[i][1].metadata.description,
        PropsArray[i][1].metadata.category,
        PropsArray[i][1].metadata.boostMATK,
        PropsArray[i][1].metadata.boostDEF,
        PropsArray[i][1].metadata.boostHP,
        PropsArray[i][1].metadata.boostMP,
        PropsArray[i][1].metadata.restoreHP,
        PropsArray[i][1].metadata.restoreMP,
      ]);
    }

    var result = await this.state.gamePropsContract.methods
      .addAsset(PropsParams)
      .send({ from: this.state.currentAddress })
      .on("transactionHash", function (hash) {
        console.info(hash);
        // return hash;
      })
      .on("receipt", function (receipt) {
        return receipt;
      });
    console.log("reuslt", result);
  };

  getAsset = async () => {
    // var result = await this.state.gamePropsContract.methods.getAsset(1).call();

    var tokenURI = await this.state.gamePropsContract.methods.uri(9).call();
    console.log("tokenURI", tokenURI);
  };

  setWhiteList = async () => {
    var reuslt = await this.state.gamePropsContract.methods
      .setMintWhiteLists(this.state.currentAddress, true)
      .send({ from: this.state.currentAddress });
    console.log("reuslt", reuslt);
  };

  mint = async () => {
    this.state.gamePropsContract.methods
      .mint("0x1D927A0d76696f574c47D7Ed8d7201BaFe3AebD0", 0, 1000)
      .send({ from: this.state.currentAddress });
  };

  getBalance = async () => {
    var reuslt = await this.state.gamePropsContract.methods
      .balanceOfBatch(
        [
          "0x7a07807114941aE3Df9ecB92e592E03a0114b1E6",
          "0x7a07807114941aE3Df9ecB92e592E03a0114b1E6",
        ],
        [0, 1]
      )
      .call();
    console.log("reuslt", reuslt);
  };

  createAllMarketItem = async () => {
    var PropsArray = Array.from(Props);
    var PropsParams = [];
    for (var i = 0; i < PropsArray.length; i++) {
      PropsParams.push([PropsArray[i][1].tokenId, PropsArray[i][1].price]);
    }

    this.state.marketContract.methods
      .addItem(PropsParams)
      .send({ from: this.state.currentAddress });
  };

  createMarketItem = async () => {
    this.state.marketContract.methods
      .addItem([
        [8, 5],
        [9, 5],
      ])
      .send({ from: this.state.currentAddress });
  };

  createSpell = async () => {
    var spells = [
      {
        name: "spell1",
        description: "this is spell1",
        costHP: 10,
        costMP: 10,
        boostHP: 10,
        boostMATK: 10,
        boostDEF: 10,
        reduceMATK: 10,
        reduceDEF: 10,
        summom: [],
        propBundle: [
          [1, 1],
          [2, 1],
        ],
      },
    ];

    this.state.gameManageContract.methods
      .createSpell(2, spells)
      .send({ from: this.state.currentAddress });
  };

  createAllMonster = async () => {
    var MonstersArray = Array.from(Monsters);
    var MonstersParam = [];

    for (var i = 0; i < MonstersArray.length; i++) {
      MonstersParam.push({
        role: MonstersArray[i][1].role,
        name: MonstersArray[i][1].name,
        hp: MonstersArray[i][1].hp,
        mp: MonstersArray[i][1].mp,
        matk: MonstersArray[i][1].matk,
        def: MonstersArray[i][1].def,
        propBundle: MonstersArray[i][1].propBundle,
      });
    }

    var result = await this.state.gameManageContract.methods
      .createMonster(MonstersParam)
      .send({ from: this.state.currentAddress });

    console.log("result", result);
  };

  getAllMonster = async () => {
    var monsterIds = await this.state.gameManageContract.methods
      .getMonsterCount()
      .call();

    for (var i = 0; i <= monsterIds; i++) {
      var monster = await this.state.gameManageContract.methods
        .getMonster(i)
        .call();
      console.log("monster", i, monster);
    }
  };

  getSpells = async () => {
    var spellIds = await this.state.gameManageContract.methods
      .getSpellCount()
      .call();

    for (var i = 0; i <= spellIds; i++) {
      var spell = await this.state.gameManageContract.methods
        .getSpell(i)
        .call();
      console.log("spell", i, spell);
    }
  };

  createEntity = async () => {
    var result = await this.state.WorldContract.methods
      .createEntity()
      .send({ from: this.state.currentAddress });
    console.log("result", result);
  };

  registerComponents = async () => {
    var components = this.state.components;
    var componentsArray = Object.values(components);
    for (var item of componentsArray) {
      var result = await this.state.WorldContract.methods
        .registerComponent(item)
        .send({ from: this.state.currentAddress });
    }
  };

  addComponents = async () => {
    var result = await this.state.WorldContract.methods
      .addComponent(0, this.state.components.PlayerState)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(0, this.state.components.Character)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(0, this.state.components.CharacterSpell)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(0, this.state.components.Equipment)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(0, this.state.components.Battle)
      .send({ from: this.state.currentAddress });

    var result = await this.state.WorldContract.methods
      .addComponent(1, this.state.components.CharacterSpell)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(1, this.state.components.Character)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(1, this.state.components.PropBundle)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(1, this.state.components.PlayerState)
      .send({ from: this.state.currentAddress });

    var result = await this.state.WorldContract.methods
      .addComponent(2, this.state.components.PropBundle)
      .send({ from: this.state.currentAddress });
    var result = await this.state.WorldContract.methods
      .addComponent(2, this.state.components.Spell)
      .send({ from: this.state.currentAddress });
  };

  registerSystems = async () => {
    var systems = this.state.systems;
    var systemsArray = Object.values(systems);
    for (var item of systemsArray) {
      var result = await this.state.WorldContract.methods
        .registerSystem(item)
        .send({ from: this.state.currentAddress });
    }
  };

  registerASystem = async () => {
    var result = await this.state.WorldContract.methods
      .registerSystem("0xc2768402461F41f2031588C4854B1dB929E54eC8")
      .send({ from: this.state.currentAddress });
  };

  queryState = async () => {
    var entityState = await this.state.WorldContract.methods
      .getEntityState(2)
      .call();
    console.log("entityState", entityState);
    var systemState = await this.state.WorldContract.methods
      .getSystemState(this.state.systems.GameManage)
      .call();
    console.log("systemState", systemState);
    var hasComponent = await this.state.WorldContract.methods
      .hasComponent(2, this.state.components.Spell)
      .call();
    console.log("hasComponent", hasComponent);
  };

  render() {
    return (
      <div>
        <h1>World</h1>
        <button onClick={this.createEntity.bind(this)}>Create Entity</button>
        <button onClick={this.registerComponents.bind(this)}>
          Register Components
        </button>
        <button onClick={this.addComponents.bind(this)}>add Components</button>
        <button onClick={this.registerSystems.bind(this)}>
          Register systems
        </button>
        <button onClick={this.registerASystem.bind(this)}>
          Register a systems
        </button>
        <button onClick={this.queryState.bind(this)}>Query State</button>
        <h1>Asset</h1>
        <button onClick={this.createAllAsset.bind(this)}>
          Create All Asset
        </button>
        <button onClick={this.createAsset.bind(this)}>Create a Asset</button>
        <button onClick={this.getAsset.bind(this)}>Get Asset</button>
        <button onClick={this.mint.bind(this)}>Mint</button>
        <button onClick={this.setWhiteList.bind(this)}>Set Whitelist</button>
        <button onClick={this.getBalance.bind(this)}>Get Balance</button>
        <h1>Market</h1>
        <button onClick={this.createAllMarketItem.bind(this)}>
          Add All Market Item
        </button>
        <button onClick={this.createMarketItem.bind(this)}>
          Add Market Item
        </button>

        <h1>Spell</h1>
        <button onClick={this.createAllSpell.bind(this)}>
          Create All Spell
        </button>
        <button onClick={this.createSpell.bind(this)}>Create Spell</button>
        <button onClick={this.getSpells.bind(this)}>Get Spells</button>

        <h2>Monster</h2>

        <button onClick={this.createAllMonster.bind(this)}>
          Create All Monster
        </button>
        <button onClick={this.getAllMonster.bind(this)}>Get Monster</button>
      </div>
    );
  }
}

export default Manage;
