import React, { Component } from "react";
import "./Player.css";
import TempAvatar from "../../assets/characters/head1.png";
import GameLib from "../../libs/GameLib";
import CharacterImages from "../../libs/Characters";
import PlayerMATKIcon from "../../assets/ui/PlayerMATKIcon.png";
import PlayerDEFIcon from "../../assets/ui/PlayerDEFIcon.png";
class Player extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playerData: {
        avater: TempAvatar,
        roldId: 0,
        name: "",
        hp: 0,
        mp: 0,
        matk: 0,
        def: 0,
      },
    };
  }

  async componentDidMount() {
    console.log("update player");

    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var gameLib = new GameLib(chainId);

    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));

    var playerData = await gameLib.getPlayerData(myBurnWallet.address);

    var equipment=await gameLib.getEquipment(myBurnWallet.address);

    var craftATK=0
    var craftDEF=0
    if(equipment[0]){
      var craftAttribute=await gameLib.getCraftAttributes(equipment[1])
      craftATK=craftAttribute[0]
      craftDEF=craftAttribute[1]
    }


    var avater;
    for (var item of CharacterImages) {
      if (playerData[0] == item.id) {
        avater = item.head;
      }
    }

    this.setState({
      playerData: {
        avater: avater,
        roldId: playerData[0],
        name: playerData[1],
        hp: playerData[2],
        mp: playerData[3],
        matk: parseInt(playerData[4])+parseInt(craftATK),
        def: parseInt(playerData[5])+parseInt(craftDEF),
      },
    });
  }

  render() {
    return (
      <div className="Player">
        <div className="PlayerAvatar">
          <img src={this.state.playerData.avater} width={78} />
        </div>
        <div className="PlayerData">
          <div className="PlayerName">{this.state.playerData.name}</div>
          <div className="PlayerHP">
            <div className="PlayerHPTitle">HP</div>

            <div className="PlayerValueBg">
              <div
                className="PlayerHPValue"
                style={{ width: this.state.playerData.hp + "px" }}
              >
                {this.state.playerData.hp}
              </div>
            </div>
          </div>
          <div className="PlayerMP">
            <div className="PlayerMPTitle">MP</div>
            <div className="PlayerValueBg">
              <div
                className="PlayerMPValue"
                style={{ width: this.state.playerData.mp + "px" }}
              >
                {this.state.playerData.mp}
              </div>
            </div>
          </div>
          <div className="PlayerATKAndDEF">
            <div className="PlayerATK" >
              <div className="PlayerATKTitle" >
                <img src={PlayerMATKIcon} width={24} />
              </div>
              <div className="PlayerATKValue">{this.state.playerData.matk}</div>
            </div>
            <div className="PlayerDEF">
              <div className="PlayerDEFTitle">
                <img src={PlayerDEFIcon} width={24} />
              </div>
              <div className="PlayerDEFValue">{this.state.playerData.def}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Player;
