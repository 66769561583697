import Web3 from "web3";
import PlayABI from "./abi/Play.json";
import GamePropsABI from "./abi/GameProps.json";
import MarketABI from "./abi/Market.json";
import CraftABI from "./abi/Craft.json";
import Chains from "./Chains";
class GameLib {
  constructor(chain) {
    this.chain = chain;
    this.web3 = new Web3(Chains.get(chain).Burn.url);
    this.Play = new this.web3.eth.Contract(
      PlayABI,
      Chains.get(chain).Contracts.Play
    );

    this.GameProps = new this.web3.eth.Contract(
      GamePropsABI,
      Chains.get(chain).Contracts.GameProps
    );

    this.Craft = new this.web3.eth.Contract(
      CraftABI,
      Chains.get(chain).Contracts.Craft
    );

    this.Market = new this.web3.eth.Contract(
      MarketABI,
      Chains.get(chain).Contracts.Market
    );
  }

  addBurnWallet(burnWallet) {
    // this.burnWallet=burnWallet;
    this.web3.eth.accounts.wallet.add(burnWallet.privateKey);
  }

  async getPlayerState(_sender) {
    var playerState = await this.Play.methods.getPlayerState(_sender).call();
    return playerState;
  }

  async createPlayer(_sender, _role, _name) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .createPlayer(_role, _name)
      .estimateGas({ from: _sender });
    var result = await this.Play.methods
      .createPlayer(_role, _name)
      .send({ from: _sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async getPlayerData(_sender) {
    var playerState = await this.getPlayerState(_sender);
    var playerId = playerState[0];
    var characterData = await this.Play.methods
      .getCharacter(0, playerId)
      .call();

    return characterData;
  }

  async getGamePropsBatch(batchAddress, batchIds) {
    var batchBalance = await this.GameProps.methods
      .balanceOfBatch(batchAddress, batchIds)
      .call();
    return batchBalance;
  }

  async checkApprove(sender, operator) {
    var isApprove = await this.GameProps.methods
      .isApprovedForAll(sender, operator)
      .call();

    console.log("sender", sender);

    if (!isApprove) {
      const gasPrice = await this.web3.eth.getGasPrice();
      const gas = await this.GameProps.methods
        .setApprovalForAll(operator, true)
        .estimateGas({ from: sender });

      var result = await this.GameProps.methods
        .setApprovalForAll(operator, true)
        .send({ from: sender, gasPrice, gas })
        .on("transactionHash", function (hash) {
          console.info(hash);
        })
        .on("receipt", function (receipt) {
          console.log("receipt", receipt);
          return receipt;
        });
    }
  }

  async buy(tokenIdAndAmount, sender) {
    var marketAddress = this.Market._address;
    await this.checkApprove(sender, marketAddress);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Market.methods
      .buy(tokenIdAndAmount)
      .estimateGas({ from: sender });
    var result = await this.Market.methods
      .buy(tokenIdAndAmount)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async sell(tokenIdAndAmount, sender) {
    var marketAddress = this.Market._address;
    await this.checkApprove(sender, marketAddress);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Market.methods
      .sell(tokenIdAndAmount)
      .estimateGas({ from: sender });

    var result = await this.Market.methods
      .sell(tokenIdAndAmount)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async getCharacterSpells(entityId, playerId) {
    var characterSpells = await this.Play.methods
      .getCharacterSpells(entityId, playerId)
      .call();

    return characterSpells.map((str) => parseInt(str));
  }

  async generateSpell(spellId, sender) {
    await this.checkApprove(sender, this.Play._address);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .generateSpell(spellId)
      .estimateGas({ from: sender });
    var result = await this.Play.methods
      .generateSpell(spellId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async getMonster(monsterId) {
    var monster = await this.Play.methods.getCharacter(1, monsterId).call();

    return monster;
  }

  async getBattleReward(monsterId) {
    var reward = await this.Play.methods.getPropBundle(monsterId).call();
    return reward;
  }

  async createBattle(monsterId, sender) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .createBattle(monsterId)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .createBattle(monsterId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async getBattle(playerId) {
    var battle = await this.Play.methods.getBattle(playerId).call();
    return battle;
  }

  async attackMonster(spellId, sender) {
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .attackMonster(spellId)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .attackMonster(spellId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async useProp(tokenId, sender) {
    await this.checkApprove(sender, this.Play._address);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .useProps(tokenId)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .useProps(tokenId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async createCraft(name, image, sender) {
    await this.checkApprove(sender, this.Play._address);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .generateCraft(name, image)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .generateCraft(name, image)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });
  }

  async getCrafts(from) {
    let token_URIs = [];
    let counts = await this.Craft.methods.balanceOf(from).call();

    for (let i = 0; i < counts; i++) {
      let token_id = await this.Craft.methods
        .tokenOfOwnerByIndex(from, i)
        .call();
      var tokenURI = await this.Craft.methods.tokenURI(token_id).call();
      var base64Str=tokenURI.split(",")[1]
      var jsonStr = atob(base64Str);
      var jsonObj = JSON.parse(jsonStr);
      jsonObj.tokenId=token_id;
      token_URIs.push(jsonObj);
    }
    return token_URIs;
  }

  async updateCraft(craftId,materialId,sender){
    await this.checkApprove(sender, this.Play._address);
    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .updateCraft(craftId,materialId)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .updateCraft(craftId,materialId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });

      console.log("result",result)
  }

  async getEquipment(sender){
    var equipment = await this.Play.methods.getPlayerEquipment(sender).call();
    return equipment;
  }

  async getCraftAttributes(craftId){
    var craftAttribute = await this.Craft.methods.getAttribute(craftId).call();
    return craftAttribute;
  }

  async setEquipment(craftId,sender){

    const gasPrice = await this.web3.eth.getGasPrice();
    const gas = await this.Play.methods
      .setPlayerEquiment(craftId)
      .estimateGas({ from: sender });

    var result = await this.Play.methods
      .setPlayerEquiment(craftId)
      .send({ from: sender, gasPrice, gas })
      .on("transactionHash", function (hash) {
        console.info(hash);
      })
      .on("receipt", function (receipt) {
        console.log("receipt", receipt);
        return receipt;
      });

      console.log("result",result)
  }


}

export default GameLib;
