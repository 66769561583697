import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Chains from "../../libs/Chains";
import Web3 from "web3"
import "./MyWallet.css";
class MyWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myBurnWallet: "",
      currentChainId: "",
      balance:"0"
    };
  }

  async componentDidMount() {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    var chainId = await window.ethereum.request({ method: "eth_chainId" });
    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    var web3 = new Web3(window.ethereum);
    var balance = await web3.eth.getBalance(myBurnWallet.address);
    this.setState({
      myBurnWallet: myBurnWallet,
      currentAddress: accounts[0],
      currentChainId: chainId,
      balance:balance
    });
  }
  removeBurnWallet = async () => {
    localStorage.removeItem("BurnWallet");
    window.location.reload();
  };
  render() {
    return (
      <div>
        <div className="MyWalletTitle">Network</div>
        {this.state.currentChainId != "" ? (
          <div className="MyWalletValue">
            {Chains.get(this.state.currentChainId).Network.chainName}
          </div>
        ) : (
          <div></div>
        )}

        <div className="MyWalletTitle">Main Wallet</div>

        <div className="MyWalletValue">
          <div>{this.state.currentAddress}</div>

          <CopyToClipboard text={this.state.currentAddress} className="MyWalletCopy">
            <div>Copy</div>
          </CopyToClipboard>
        </div>


        <div className="MyWalletTitle">Burner Wallet</div>
        <div className="MyWalletValue">
          <div>{this.state.myBurnWallet.address}</div>

          <CopyToClipboard text={this.state.myBurnWallet.address} className="MyWalletCopy">
            <div>Copy</div>
          </CopyToClipboard>
    
        </div>

        <div className="MyWalletValue">
       
        <div>Balance:{Web3.utils.fromWei(this.state.balance)}</div>
        <div className="MyWalletRemove" onClick={this.removeBurnWallet.bind(this)}>Remove</div>
        </div>
 
      </div>
    );
  }
}

export default MyWallet;
