import king from "../assets/dialog/king.png"
import princess from "../assets/dialog/princess.png"
const Dialogs = new Map([
  [
    "king",
    { title:"King" ,head:king , content: "Welcome to WizWorld and wish you good luck!" },
  ], [
    "princess",
    { title:"Princess" ,head:princess , content: "Dear wizard, I am the princess of this land and have lived here for a long time. The cold climate makes this place icy and snowy, but it also gives this land its unique beauty. Our people are brave and resilient, coexisting with the cold and in harmony with nature. If you need any help, please don't hesitate and I will do my best to help you. Hope you find everything you are looking for here." },
  ]
  
]);


export default Dialogs;