import React, { Component } from "react";
import Spells from "../../libs/Spells";
import Props from "../../libs/Props";
import GameLib from "../../libs/GameLib";
import "./Spell.css";
import { Popover } from "antd";
import { ToastContainer, toast } from "react-toastify";
class Spell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spells: Array.from(Spells),
      tabHeaderList: ["Spells", "My Spells"],
      currentTabIndex: 0,
      mySpells: [],
    };
  }

  async componentDidMount() {
    // const myArray = Array.from(Spells);
    // console.log("myArray", myArray);
    await this.getMySpells();
  }

  generateSpell = async (spellId) => {


    const response = await toast.promise(
      async () => {

        var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
        const chainId = await window.ethereum.request({ method: "eth_chainId" });
    
        var gameLib = new GameLib(chainId);
    
        gameLib.addBurnWallet(myBurnWallet);
        await gameLib.generateSpell(spellId, myBurnWallet.address);
        await this.getMySpells();
    
      },
      {
        pending: { render: "pending", position: "top-center" },
        success: "Success",
        error: "Fail",
      }
);

  };

  changeTab = async (index) => {
    this.setState({
      currentTabIndex: index,
    });

    // if (index == 0) {
    //   this.getMarket();
    // } else {
    //   this.getMyBag();
    // }
  };

  getMySpells = async () => {
    var myBurnWallet = JSON.parse(localStorage.getItem("BurnWallet"));
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    var gameLib = new GameLib(chainId);
    var playerState = await gameLib.getPlayerState(myBurnWallet.address);
    var playerId = playerState[0];
    var playerSpells = await gameLib.getCharacterSpells(0, playerId);
    console.log("playerSpells",playerSpells)
    this.setState({
      mySpells: playerSpells,
    });
  };

  render() {
    return (
      <div>

<ToastContainer></ToastContainer>
        {/* <div className="spellHeader">
          <div className="tabs">
            {this.state.tabHeaderList.map((item, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name="radio"
                  checked={this.state.currentTabIndex == index ? true : false}
                  value={index}
                  onChange={this.changeTab.bind(this, index)}
                />
                <span>{item}</span>
              </label>
            ))}
          </div>
        </div> */}
        <ul className="spells">
          {this.state.spells.map((item, index) => (
            <li key={index}>
              <div className="spellImageNameInfo">
                <Popover
                  className="spellImageName"
                  trigger="hover"
                  content={
                    <div>
                      <p>{item[1].metadata.description}</p>
                      <p>Spend HP:{item[1].metadata.costHP}</p>
                      <p>Spend MP:{item[1].metadata.costMP}</p>
                      <p>Restore HP:{item[1].metadata.boostHP}</p>
                      <p>Increase ATK:{item[1].metadata.boostMATK}</p>
                      <p>Increase DEF:{item[1].metadata.boostDEF}</p>
                      <p>Decrease ATK:{item[1].metadata.reduceMATK}</p>
                      <p>Decrease DEF:{item[1].metadata.reduceDEF}</p>
                    </div>
                  }
                >
                  <img src={item[1].image} width={48} className="spellImage" />
                  <span> {item[1].metadata.name}</span>
                </Popover>

                <div className="spellPropsBundle">
                  {item[1].metadata.propBundle.length != 0 ? (
                    item[1].metadata.propBundle.map((prop, propIndex) => (
                      <div key={propIndex} className="spellPropsBundleItem">
                        <img src={Props.get(prop[0]).image} width={32} />

                        <span> x {prop[1]}</span>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>

              {this.state.mySpells.indexOf(item[1].spellId) != -1 ? (
                <div className="spellHave">Achieved</div>
              ) : (
                <div
                  className="spellGenerate"
                  onClick={this.generateSpell.bind(this, item[1].spellId)}
                >
                  Learn
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Spell;
