import OriginalSpell from "../assets/spells/OriginalSpell.png"
import Fire from "../assets/spells/Fire.png"
import Healing from "../assets/spells/Healing.png"
import OriginalSpellEffect from "../assets/spells/effect/OriginalSpellEffect.gif"
import FireEffect from "../assets/spells/effect/FireEffect.gif"
import HealingEffect from "../assets/spells/effect/HealingEffect.gif"
const Spells = new Map([
  [
    0,
    {
      spellId: 0,
      image: OriginalSpell,
      effect:OriginalSpellEffect,
      metadata: {
        name: "Original Spell",
        description: "Ordinary magic attack",
        costHP: 0,
        costMP: 0,
        boostHP: 0,
        boostMATK: 0,
        boostDEF: 0,
        reduceMATK: 0,
        reduceDEF: 0,
        summom: [],
        propBundle:[]
      },
     
    },
  ],  [
    1,
    {
      spellId: 1,
      image: Fire,
      effect:FireEffect,
      metadata: {
        name: "Fire Magic",
        description: "Fire magic attack",
        costHP: 0,
        costMP: 10,
        boostHP: 0,
        boostMATK: 10,
        boostDEF: 0,
        reduceMATK: 0,
        reduceDEF: 0,
        summom: [],
        propBundle:[[2,1],[4,1]]
      },
    
    },
  ],[
    2,
    {
      spellId: 2,
      image: Healing,
      effect:HealingEffect,
      metadata: {
        name: "Healing",
        description: "Restore health",
        costHP: 0,
        costMP: 10,
        boostHP: 10,
        boostMATK: 0,
        boostDEF: 0,
        reduceMATK: 0,
        reduceDEF: 0,
        summom: [],
        propBundle:[[3,1],[4,1]]
      },
     
    },
  ]
]);

export default Spells;
